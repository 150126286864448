import clx from 'classnames';
const Switch = ({
  label,
  value,
  onToggle,
}: {
  label?: string;
  onToggle: () => void;
  value: boolean;
}) => {
  const _label = label ? label : value ? 'Yes' : 'No';
  const toggleSwitch = () => {
    onToggle();
  };

  return (
    <label className="flex items-center cursor-pointer">
      <div
        className={clx(
          `w-[60px] relative flex items-center h-7 rounded-full p-1 border-2 `,
          {
            'bg-white border-brand-primary-500': value,
            'bg-brand-gray-600 border-brand-gray-600': !value,
          },
        )}
        onClick={toggleSwitch}
      >
        <span
          className={clx('absolute text-xs w-full p-[8px]', {
            'text-right pr-[12px] text-brand-gray-800': !value,
            'text-left ml-[-3px] text-brand-primary-500': value,
          })}
        >
          {_label}
        </span>
        <div
          className={clx(
            `w-[20px] h-[20px] rounded-full shadow-md transform ${
              value ? 'translate-x-[28px]' : 'translate-x-[1px]'
            }`,
            {
              'bg-brand-gray-800': !value,
              'bg-brand-primary-500': value,
            },
          )}
        ></div>
      </div>
    </label>
  );
};

export default Switch;
