import clx from 'classnames';

export default function OptionButton({
  className,
  onClick,
  icon,
  label,
  selected,
}: {
  className?: string;
  onClick?: () => void;
  icon: React.ReactNode;
  label: string;
  selected?: boolean;
}) {
  return (
    <div
      className={clx(
        'flex items-center p-4 rounded-md cursor-pointer  border ',
        className,
        {
          'border-brand-gray-500 hover:bg-brand-gray-100': !selected,
          'bg-brand-primary-100 border-brand-green-500': selected,
        },
      )}
      onClick={onClick}
    >
      {icon && <div className="mr-2">{icon}</div>}
      <div>{label}</div>
    </div>
  );
}
