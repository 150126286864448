import Switch from '../../atoms/Switch';

export function FilterToggle({
  value,
  onToggle,
}: {
  value: boolean;
  onToggle: () => void;
}) {
  return (
    <div className="flex justify-between items-center">
      <Switch
        label={value ? 'Yes' : 'No'}
        value={value}
        onToggle={() => onToggle()}
      />
    </div>
  );
}
