import { uuidv4 } from '@firebase/util';
import { Country } from '../../../shared/domain/Country/Country';

export class Company {
  public readonly id: string;
  public readonly fullTimeHours: number;
  name: string;
  address: string;
  vat?: string;
  country?: Country;
  city?: string;
  postCode?: string;

  constructor({
    id,
    fullTimeHours,
    name,
    address,
    vat,
    country,
    city,
    postCode,
  }: {
    id: string;
    name: string;
    fullTimeHours: number;
    address: string;
    vat?: string;
    country?: Country;
    city?: string;
    postCode?: string;
  }) {
    this.id = id;
    this.name = name;
    this.fullTimeHours = fullTimeHours;
    this.address = address;
    this.vat = vat;
    this.country = country;
    this.city = city;
    this.postCode = postCode;
  }

  update({
    name,
    address,
    vat,
    countryId,
    city,
  }: {
    name?: string;
    address?: string;
    vat?: string | null;
    countryId?: string | null;
    city?: string | null;
  }) {
    if (name) {
      this.name = name;
    }
    if (address) {
      this.address = address;
    }
    if (vat !== undefined) {
      this.vat = vat;
    }
    if (countryId !== undefined) {
      this.country = countryId
        ? new Country({ id: countryId, name: '', code: '' })
        : undefined;
    }
    if (city !== undefined) {
      this.city = city;
    }
  }

  static fromJson(data: any) {
    return new Company({
      id: data.id,
      name: data.name,
      fullTimeHours: data.fullTimeHours,
      address: data.address,
      vat: data.vat,
      country: data.country ? Country.fromJson(data.country) : undefined,
      city: data.city,
    });
  }

  static onboardingCompany({ name }: { name: string }) {
    return new Company({
      id: uuidv4().toString(),
      name,
      fullTimeHours: 40,
      address: '',
    });
  }

  formatUKCompanyAddress(): string {
    return (
      `${this.address ?? ''} \n ${this.postCode ?? ''} ` +
      `${this.city ?? ''}, ${this.country?.name ?? ''}`
    );
  }

  static getCompanyNameByEmailDomain(email: string) {
    const partes = email.split('@');
    if (partes.length > 1) {
      const dominio = partes[1].split('.');
      return dominio[0];
    }
    return email;
  }
}
