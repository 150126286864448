import useSWR from 'swr';
import { fetcher } from '../../utils';
import AdminPayrollConfig from '@/lib/contexts/payroll/domain/AdminPayrollConfig';

export function useCompanyAdminPayrollConfig() {
  const { data, error, mutate } = useSWR<AdminPayrollConfig>(
    `/api/v2/payroll/admin-company-config`,
    fetcher,
  );

  return {
    data: data ? AdminPayrollConfig.fromJson(data) : data,
    loading: !error && !data,
    error,
    refetch: () => mutate(),
  };
}
