import { chartStyle } from './style';
import { countryCodeToEmoji, seededRandom } from 'lib/utils';
import { userFullName } from 'lib/utils/user';
import { Department } from '../../../lib/contexts/hr/company/Department';

const bgColors = [
  '#A4CFFF',
  '#00E59D',
  '#FF8C8B',
  '#4FCE70',
  '#C7A6F2',
  '#FEC042',
  '#EC4899',
  '#6366F1',
];

const randomBgColor = (name: string): string => {
  const index = seededRandom(name, 0, bgColors.length - 1);
  return bgColors[index];
};

export const generateButton = ({ node }): string => {
  const color = node.children ? '#00835A' : '#1F1F1F';
  const angle = node.children ? 'up' : 'down';

  return `
  <div style="color:#fff;border-radius:8px;padding:4px 6px;font-size:10px;margin:auto auto;background-color: ${color};>
    <span style="font-size:9px">
        <i class="fas fa-angle-${angle}"></i>
    </span>
    ${node.data._directSubordinates}
  </div>`;
};

export const generateNodeContent =
  (currentUserId: string, browser: string) =>
  (node): string => {
    const {
      id,
      first_name,
      last_name,
      personal_address,
      job_title,
      alert: userWithAlert,
      department,
    } = node.data;

    const bgColor = randomBgColor(userFullName({ first_name, last_name }));
    const currentUserStyle =
      currentUserId === id
        ? '4px solid #00E59D;'
        : userWithAlert === true
          ? '4px solid red;'
          : '';

    const generalBrowserCard = `
      ${chartStyle}
    <section style="height:102px; width:236px; display:flex; flex-direction: column; justify-content: center; align-items: center; border-radius: 0.5rem; border-width: 1px; border-color: #9CA3AF;">
      <div style="display:flex; flex-direction:column; position:absolute; transform: translateY(-3rem);">
        <div style="display:flex; align-items:center; justify-content: center; border-radius: 9999px; color: white; background-color:${bgColor}; width: 3rem; height:3rem; border: ${currentUserStyle}">
          ${first_name?.toString().slice(0, 1).toUpperCase() ?? ''}${
            last_name?.toString().slice(0, 1).toUpperCase() ?? ''
          }
        </div>
        <div style="position:absolute; transform: translate(50%, 2rem);">
        ${
          personal_address?.city || userWithAlert
            ? `
          <div class="tooltip">
            <span style="height:24px; width:24px; " class="fi fi-${personal_address?.country_iso?.toLowerCase()}"></span>
            <div class="top">
              <p style="min-width: 100px;">
                ${
                  personal_address?.city
                    ? `Based in ${personal_address?.city}`
                    : ''
                }
              </p>
            </div>
          </div>
        `
            : ''
        }
        </div>
      </div>
      <div style="display:flex; flex-direction: column; padding-top: 10px; font-family: Red Hat Display, sans-serif; justify-content: center; align-items: center;">
        <h4 class="nameLink" style="text-align:center; font-weight:500; color: #1F1F1F; font-size: 14px; line-height:20px;">
            ${first_name ?? ''} ${last_name ?? ''}
        </h4>
        <p style="font-family: 'Red Hat Display', 'sans-serif'; font-weight: 400; font-size: 12px; line-height: 16px; color:#868686;text-align:center;">${
          job_title ?? ''
        }</p>
        <p style="font-family: 'Red Hat Display', 'sans-serif'; font-weight: 400; font-size: 12px; line-height: 16px; color: black;text-align:center; " class="px-2 py-[0.5] mt-1 rounded ${
          department?.color ?? Department.getDefaultColor(department?.name)
        }">${department?.name?.toUpperCase() ?? ''}</p>
      </div>
    </section>
    `;

    const safariBrowserCard = `
      <section style="height:132px; width:236px; display:flex; flex-direction: column; justify-content: center; align-items: center; border-radius: 0.5rem; border-width: 1px; border-color: #9CA3AF;">
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <div style="display:flex; align-items:center; justify-content: center; border-radius: 9999px; color: white; background-color:${bgColor}; width: 3rem; height:3rem; border: ${currentUserStyle}">
          ${first_name?.toString().slice(0, 1).toUpperCase() ?? ''} ${
            last_name?.toString().slice(0, 1).toUpperCase() ?? ''
          }
        </div>
          <div style="font-size:18px">
           ${countryCodeToEmoji(personal_address?.country_iso ?? '')}
          </div>
      </div>
      <div style="display:flex; flex-direction: column; font-family: Red Hat Display, sans-serif; justify-content: center; align-items: center;"
        <h4 class="nameLink" style="text-align:center; font-weight:500; color: #1F1F1F; font-size: 14px; line-height:20px;">
            ${first_name ?? ''} ${last_name ?? ''}
        </h4>
        <p style="font-family: 'Red Hat Display', 'sans-serif'; font-weight: 400; font-size: 12px; line-height: 16px; color:#868686;text-align:center;">${
          job_title ?? ''
        }</p>
      </div>
    </section>
    `;

    return browser === 'Safari' ? safariBrowserCard : generalBrowserCard;
  };
