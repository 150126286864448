'use client';
import { ArrowCircleLeftIcon } from '@heroicons/react/solid';
import { Button } from '../../../../atoms/Button';
import { useState } from 'react';
import clx from 'classnames';
import PencilIcon from '../../../../../lib/icons/pencil';
import SmallTrashIcon from '../../../../../lib/icons/SmallTrash';
import { Location } from '../../../../../lib/contexts/hr/company/domain/Location/Location';
import { DeleteLocationModal } from './DeleteLocationModal';
import { EditLocationForm } from './EditLocationForm';
import { countryCodeToEmoji } from '../../../../../lib/utils';
import LocationTypeChip from '../../../../molecules/LocationType';
import { EditLocationModal } from './EditLocationModal';

export default function EditLocations({
  locations,
  onGoBack,
  refetch,
}: {
  locations: Location[];
  onGoBack?: () => void;
  refetch: () => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [showEditLocation, setShowEditLocation] = useState<Location | false>(
    false,
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  return (
    <>
      {showEditLocation && (
        <EditLocationModal
          open={!!showEditLocation}
          location={showEditLocation}
          onClose={() => {
            setShowEditLocation(false);
          }}
          refetch={refetch}
        />
      )}
      {showDeleteModal && (
        <DeleteLocationModal
          open={!!showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
        />
      )}

      <div className="w-full bg-white rounded-md min-h-screen p-4">
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center">
            {onGoBack && (
              <span
                className="cursor-pointer"
                onClick={() => (onGoBack ? onGoBack() : {})}
              >
                <ArrowCircleLeftIcon className="w-8 mr-3" />
              </span>
            )}
            <h2 className="font-bold text-xl h-[40px] flex items-center">
              Locations
            </h2>
          </div>
        </div>
        <div className="md:grid-cols-2 grid-cols-1 grid py-10">
          <div className="w-full">
            <div className="font-bold p-2 text-lg">Add locations</div>
            <EditLocationForm
              className="p-2 pr-10"
              loading={loading}
              onSave={refetch}
            />
          </div>
          <div className="w-full md:p-10 pt-10 md:pt-0 md:border-l border-brand-gray-100">
            <div>
              {locations.length === 0 ? (
                <div
                  className={clx(
                    'w-full h-full text-center p-8 flex justify-center items-center min-h-36 bg-brand-gray-200 text-brand-gray-600 rounded border-dashed border-2 border-brand-gray-600',
                    {
                      ['blur animate-pulse']: loading,
                    },
                  )}
                >
                  In the "Add Locations" section, input all the locations or
                  offices where your organisation operates.
                  <br />
                  The locations you include will be listed here for your review.
                </div>
              ) : (
                <div
                  className={clx(
                    'w-full h-full flex flex-col justify-center items-center',
                    {
                      ['blur animate-pulse']: loading,
                    },
                  )}
                >
                  {locations
                    ?.sort((a, b) =>
                      a.city.toLowerCase() > b.city.toLowerCase() ? 1 : -1,
                    )
                    .map((d, k) => (
                      <div
                        key={k}
                        className={clx(
                          'flex justify-end py-2 mb-4 pl-4 items-center w-full rounded',
                          'bg-brand-gray-100',
                        )}
                      >
                        <div className="font-semibold ml-0 mr-auto">
                          {d.city}{' '}
                          {d.country ? countryCodeToEmoji(d.country.code) : ''}
                        </div>
                        <LocationTypeChip
                          locationType={d.type}
                          className="w-[120px]"
                        />
                        <div className="ml-6 flex">
                          <Button
                            className="py-1 px-2 flex justify-center items-center"
                            type="flat"
                            onClick={() => setShowEditLocation(d)}
                          >
                            <PencilIcon />
                          </Button>
                          <Button
                            className="py-1 px-2 pr-5"
                            type="flat"
                            onClick={() => setShowDeleteModal(true)}
                          >
                            <SmallTrashIcon />
                          </Button>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
