export class Country {
  public readonly id: string;
  public readonly name: string;
  public readonly code: string;

  constructor({ id, name, code }: { id: string; name: string; code: string }) {
    this.id = id;
    this.name = name;
    this.code = code;
  }

  static fromJson(data: any) {
    return new Country({
      id: data.id,
      name: data.name,
      code: data.code,
    });
  }
  static fromPrisma(data: any) {
    return new Country({
      id: data.id,
      name: data.name,
      code: data.alpha_2_code,
    });
  }

  static countryId(countryId: string) {
    return new Country({
      id: countryId,
      name: '',
      code: '',
    });
  }
}
