import { Country } from '../../../../shared/domain/Country/Country';
import { LocationTypeEnum } from './LocationType';
import { uuidv4 } from '@firebase/util';
import dayjs, { Dayjs } from 'dayjs';

export class Location {
  public readonly id: string;
  readonly companyId: string;
  city?: string;
  country?: Country;
  type?: LocationTypeEnum;
  updatedAt?: Dayjs;

  constructor({
    id,
    companyId,
    city,
    country,
    type,
    updatedAt,
  }: {
    id: string;
    companyId: string;
    city?: string;
    country?: Country;
    type?: LocationTypeEnum;
    updatedAt?: Dayjs;
  }) {
    this.id = id;
    this.companyId = companyId;
    this.city = city;
    this.country = country;
    this.type = type;
    this.updatedAt = updatedAt;
  }

  static create({
    companyId,
    city,
    countryId,
    type,
  }: {
    companyId: string;
    city?: string;
    countryId?: string;
    type?: LocationTypeEnum;
  }): Location {
    return new Location({
      id: uuidv4().toString(),
      companyId,
      city: city ?? undefined,
      country: countryId ? Country.countryId(countryId) : undefined,
      type: type ?? undefined,
    });
  }

  static fromJson(data: {
    id: string;
    companyId: string;
    city?: string;
    country?: {
      id: string;
      name: string;
      code: string;
    };
    type?: string | LocationTypeEnum;
    updatedAt?: string;
  }) {
    return new Location({
      id: data.id,
      companyId: data.companyId,
      city: data.city,
      country: data.country ? Country.fromJson(data.country) : undefined,
      type: data.type as LocationTypeEnum,
      updatedAt: data.updatedAt ? dayjs(data.updatedAt) : undefined,
    });
  }

  static id = ({ id }: { id: string }) => {
    return new Location({ id, companyId: '' });
  };

  static fromPrisma(data: {
    id: string;
    company_id: string;
    city?: string;
    country?: {
      id: string;
      name: string;
      alpha_2_code: string;
    };
    type?: string;
    updated_at?: Date;
  }) {
    return new Location({
      id: data.id,
      companyId: data.company_id,
      city: data.city,
      country: data.country ? Country.fromPrisma(data.country) : undefined,
      type: data.type as LocationTypeEnum,
      updatedAt: data.updated_at ? dayjs(data.updated_at) : undefined,
    });
  }
}

export const defaultLocations = (companyId: string, countryId: string) => {
  return [
    Location.create({
      companyId,
      city: 'Remote',
      countryId: undefined,
      type: LocationTypeEnum.REMOTE,
    }),
    Location.create({
      companyId,
      city: 'London',
      countryId,
      type: LocationTypeEnum.OFFICE,
    }),
  ];
};
