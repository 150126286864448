'use client';
import { ArrowCircleLeftIcon } from '@heroicons/react/solid';
import { Button } from '../../../../atoms/Button';
import { Input } from '../../../../atoms/Input';
import { useState } from 'react';
import { useCompanyDepartments } from '../../../../../lib/hooks/company/useCompanyDepartments';
import clx from 'classnames';
import PencilIcon from '../../../../../lib/icons/pencil';
import { EditDepartmentModal } from './EditDepartmentModal';
import { Department } from '../../../../../lib/contexts/hr/company/Department';
import { DeleteDepartmentModal } from './DeleteDepartmentModal';
import SmallTrashIcon from '../../../../../lib/icons/SmallTrash';

export default function EditDepartments({
  departments,
  onGoBack,
  refetchDepartments,
}: {
  departments: Department[];
  onGoBack?: () => void;
  refetchDepartments: () => void;
}) {
  const [inputDepartment, setInputDepartment] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showEditDepartment, setShowEditDepartment] = useState<
    Department | false
  >(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const onSave = async () => {
    if (inputDepartment && inputDepartment?.trim() !== '') {
      setInputDepartment('');
      setLoading(true);
      await fetch('/api/v1/company/save-department', {
        method: 'POST',
        body: JSON.stringify({
          name: inputDepartment.trim(),
        }),
      });
      refetchDepartments();
      setLoading(false);
    }
  };

  return (
    <>
      {showEditDepartment && (
        <EditDepartmentModal
          department={showEditDepartment}
          open={!!showEditDepartment}
          onClose={() => {
            setShowEditDepartment(false);
            refetchDepartments();
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteDepartmentModal
          open={!!showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
        />
      )}

      <div className="w-full bg-white rounded-md min-h-screen p-4">
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center">
            {onGoBack && (
              <span
                className="cursor-pointer"
                onClick={() => (onGoBack ? onGoBack() : {})}
              >
                <ArrowCircleLeftIcon className="w-8 mr-3" />
              </span>
            )}
            <h2 className="font-bold text-xl h-[40px] flex items-center">
              Departments
            </h2>
          </div>
        </div>
        <div className="md:grid-cols-2 grid-cols-1 grid py-10">
          <div className="w-full">
            <div className="font-bold p-2 text-lg">Add departments</div>
            <div className="p-2 pr-10">
              <Input
                onChange={(e) => setInputDepartment(e.target.value)}
                value={inputDepartment}
                placeholder="Type the department name and press Save"
                onPressEnter={() => onSave()}
              />
              <Button
                className={'mt-4'}
                onClick={() => onSave()}
                disabled={!inputDepartment || inputDepartment?.trim() === ''}
              >
                Save
              </Button>
            </div>
          </div>
          <div className="w-full md:p-10 pt-10 md:pt-0 md:border-l border-brand-gray-100">
            <div>
              {departments.length === 0 ? (
                <div
                  className={clx(
                    'w-full h-full text-center p-8 flex justify-center items-center min-h-36 bg-brand-gray-200 text-brand-gray-600 rounded border-dashed border-2 border-brand-gray-600',
                    {
                      ['blur animate-pulse']: loading,
                    },
                  )}
                >
                  In the “Add Departments” section, list all departments within
                  your organization to structure your team effectively. <br />
                  Departments added will appear here for your review.
                </div>
              ) : (
                <div
                  className={clx(
                    'w-full h-full flex flex-col justify-center items-center',
                    {
                      ['blur animate-pulse']: loading,
                    },
                  )}
                >
                  {departments
                    ?.sort((a, b) =>
                      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
                    )
                    .map((d, k) => (
                      <div
                        key={k}
                        className={clx(
                          'flex justify-between py-2 mb-4 pl-4 items-center w-full rounded',
                          d.color,
                        )}
                      >
                        <div className="font-semibold">{d.name}</div>
                        <div className="flex">
                          <Button
                            className="py-1 px-2 flex justify-center items-center"
                            type="flat"
                            onClick={() => setShowEditDepartment(d)}
                          >
                            <PencilIcon />
                          </Button>
                          <Button
                            className="py-1 px-2 pr-5"
                            type="flat"
                            onClick={() => setShowDeleteModal(true)}
                          >
                            <SmallTrashIcon />
                          </Button>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
