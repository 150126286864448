import { FC, ReactNode } from 'react';
import clx from 'classnames';

interface ITooltip {
  children: ReactNode;
  tooltip: string;
  className?: string;
  disabled?: boolean;
  rounded?: boolean;
}

const Tooltip: FC<ITooltip> = ({
  children,
  tooltip,
  className,
  disabled = false,
  rounded = true,
}) => {
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <span className="group relative">
      <span
        className={clx(
          "text-sm pointer-events-none absolute left-1/2 -translate-x-1/2 bg-black px-2 py-1 text-white opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-black before:content-[''] group-hover:opacity-100",
          className,
          {
            'rounded-[40px]': rounded,
            rounded: !rounded,
            'whitespace-nowrap': !className?.includes('whitespace-normal'),
            '-top-10':
              !className?.includes('-top-') &&
              tooltip?.split('\n').length === 1,
            ['px-4']: tooltip?.split('\n').length > 1,
          },
        )}
        style={{
          marginTop:
            tooltip?.split('\n').length > 1
              ? `-${tooltip?.split('\n').length * 20}px`
              : undefined,
        }}
      >
        {tooltip.split('\n').map((line, i) => {
          if (i > 0) {
            return (
              <>
                <br />
                {line}
              </>
            );
          }
          return line;
        })}
      </span>

      {children}
    </span>
  );
};

export default Tooltip;
