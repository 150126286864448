import { VFC } from 'react';
import clx from 'classnames';
import { RiLinkedinFill, RiTwitterFill } from 'react-icons/ri';
import { UserProfileDataResponse } from 'lib/prisma/queries/user/get-user-profile-data';

const Social: VFC<{ name: 'twitter' | 'linkedin'; url: string }> = ({
  name,
  url,
}) => {
  const [socialURL, Icon] = {
    twitter: [url, RiTwitterFill],
    linkedin: [url, RiLinkedinFill],
  }[name];

  return (
    <a
      className={clx(
        'flex items-center justify-center w-8 h-8 rounded-md mr-2',
        {
          'bg-[#2867B2]': name === 'linkedin',
          'bg-[#1DA1F2]': name === 'twitter',
        },
      )}
      href={socialURL as string}
      target="_blank"
    >
      <Icon className="text-white text-2xl" />
    </a>
  );
};

export const SocialHandlersIcons: VFC<Partial<UserProfileDataResponse>> = ({
  personal_linkedin,
  personal_twitter,
}) => {
  return (
    <div className="flex mt-4">
      {personal_linkedin && <Social name="linkedin" url={personal_linkedin} />}
      {personal_twitter && <Social name="twitter" url={personal_twitter} />}
    </div>
  );
};
