'use client';
import MenuGridItem from '../../../organisms/MenuGrid/MenuGridItem';
import MenuGrid from '../../../organisms/MenuGrid';
import CompanyIcon from '../../../../lib/icons/company';
import { useCompany } from '../../../../lib/hooks/company/useCompany';
import FolderIcon from '../../../../lib/icons/folder';
import { useState } from 'react';
import { LoadingSpinner } from '../../../atoms/LoadingSpinner';
import EditHQDetails from './EditHQDetails';
import { countryCodeToEmoji } from '../../../../lib/utils';
import EditDepartments from './EditDepartments';
import { useCompanyDepartments } from '../../../../lib/hooks/company/useCompanyDepartments';
import { useCompanyLocations } from '../../../../lib/hooks/company/useCompanyLocations';
import EditLocations from './EditLocations';
import GlobeIcon from '../../../../lib/icons/globe';
import { useCompanyAdminPayrollConfig } from '@/lib/hooks/payroll/useCompanyAdminPayrollConfig';
import CreditCardIcon from '@/lib/icons/credit-card';

enum CompanySettingTaps {
  COMPANY = 'company',
  DEPARTMENTS = 'departments',
  LOCATIONS = 'locations',
}

export default function CompanySettingsScene() {
  const {
    data: company,
    loading: loadingCompany,
    refetch: refetchCompany,
  } = useCompany();
  const {
    data: companyAdminPayrollConfig,
    loading: loadingCompanyAdminPayrollConfig,
    refetch: refetchCompanyAdminPayrollConfig,
  } = useCompanyAdminPayrollConfig();
  const {
    data: departments,
    loading: loadingDepartments,
    refetch: refetchDepartments,
  } = useCompanyDepartments();
  const {
    data: locations,
    loading: loadingLocations,
    refetch: refetchLocations,
  } = useCompanyLocations();
  const [currentTab, setCurrentTab] = useState<CompanySettingTaps | undefined>(
    undefined,
  );
  const loading = loadingCompany || loadingDepartments || loadingLocations;

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner />
      </div>
    );
  }

  const handleBillingClick = () => {
    window.open(
      process.env.NEXT_PUBLIC_BILLING_STRIPE_CUSTOMER_PORTAL,
      '_blank',
    );
  };

  return (
    <>
      {currentTab === CompanySettingTaps.COMPANY && (
        <>
          <EditHQDetails
            company={company}
            payrollConfig={companyAdminPayrollConfig}
            onGoBack={() => {
              refetchCompany();
              refetchCompanyAdminPayrollConfig();
              setCurrentTab(undefined);
            }}
            refetch={() => {
              refetchCompany();
              refetchCompanyAdminPayrollConfig();
            }}
          />
        </>
      )}
      {currentTab === CompanySettingTaps.DEPARTMENTS && (
        <>
          <EditDepartments
            departments={departments}
            refetchDepartments={() => refetchDepartments()}
            onGoBack={() => {
              setCurrentTab(undefined);
            }}
          />
        </>
      )}
      {currentTab === CompanySettingTaps.LOCATIONS && (
        <>
          <EditLocations
            locations={locations}
            refetch={() => refetchLocations()}
            onGoBack={() => {
              setCurrentTab(undefined);
            }}
          />
        </>
      )}
      {!currentTab && (
        <>
          <MenuGrid cols={4}>
            <MenuGridItem
              iconBgColor="bg-brand-yellow-100"
              icon={
                <CompanyIcon
                  color="#D88400"
                  sizeH={24}
                  sizeW={24}
                  viewBox={'0 0 24 24'}
                />
              }
              title="HQ Details"
              onClick={() => setCurrentTab(CompanySettingTaps.COMPANY)}
            >
              <div className="mb-1">{company?.name}</div>
              {company?.city && (
                <div className="mb-1">
                  {company?.city}{' '}
                  {company?.country
                    ? countryCodeToEmoji(company?.country?.code)
                    : ''}
                </div>
              )}
            </MenuGridItem>
            <MenuGridItem
              iconBgColor="bg-brand-purple-100"
              icon={
                <CreditCardIcon
                  color="#7231C7"
                  className={'text-brand-purple-900'}
                  size={'30'}
                />
              }
              title="Billing"
              buttonText={'Manage'}
              onClick={handleBillingClick}
            >
              <div className="mb-1">Manage billing and payments</div>
            </MenuGridItem>
            <MenuGridItem
              iconBgColor="bg-brand-primary-100"
              icon={<FolderIcon color="#1F973F" />}
              title="Departments"
              onClick={() => setCurrentTab(CompanySettingTaps.DEPARTMENTS)}
            >
              <div className="mb-1">
                {!departments?.length
                  ? 'Add company departments'
                  : `${departments.length} departments`}
              </div>
            </MenuGridItem>

            <MenuGridItem
              iconBgColor="bg-brand-rose-100"
              icon={
                <GlobeIcon
                  color="#FF239A"
                  className={'text-brand-rose-900'}
                  size={30}
                />
              }
              title="Locations"
              onClick={() => setCurrentTab(CompanySettingTaps.LOCATIONS)}
            >
              <div className="mb-1">
                {!locations?.length
                  ? 'Add company locations'
                  : `${locations.length} locations`}
              </div>
            </MenuGridItem>
            <div></div>
          </MenuGrid>
        </>
      )}
    </>
  );
}
