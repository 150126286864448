import { Dialog as UIDialog } from '@headlessui/react';
import { ReactNode } from 'react';
import { Button } from '../../atoms/Button';
import clx from 'classnames';
import { XIcon } from '@heroicons/react/outline';

export default function Dialog({
  children,
  open,
  onClose,
  title,
  text,
  saveButtonText,
  saveButtonOnClick,
  maxWidth,
  disabledSaveButton,
}: {
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
  title?: string;
  text?: string;
  saveButtonText?: string | ReactNode;
  saveButtonOnClick?: () => void;
  maxWidth?: string;
  disabledSaveButton?: boolean;
}) {
  return (
    <UIDialog
      open={open}
      className="fixed top-0 left-0 flex items-center justify-center w-full h-screen bg-black bg-opacity-60 backdrop-blur-sm z-50"
      onClose={onClose}
      as="div"
    >
      <div
        className={clx('w-full bg-white rounded py-8 px-6 relative', {
          [maxWidth as string]: maxWidth,
          ['max-w-xl']: !maxWidth,
        })}
      >
        <div className="">
          <button className="absolute top-4 right-4" onClick={onClose}>
            <XIcon className="w-5 h-5 text-brand-gray-800" />
          </button>
        </div>
        {title && <div className="text-xl font-bold mb-4">{title}</div>}
        {text && <div>{text}</div>}
        {children}
        {saveButtonText && (
          <div className="w-full mt-8">
            <Button
              className="font-body w-full"
              disabled={!saveButtonOnClick || disabledSaveButton}
              onClick={saveButtonOnClick}
            >
              {saveButtonText}
            </Button>
          </div>
        )}
      </div>
    </UIDialog>
  );
}
