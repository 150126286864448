import { IconProps } from '../types';

export default function SmallTrashIcon(props: IconProps) {
  // Changing size doesnt work
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6412 5.2998C10.1708 5.2998 9.75427 5.53766 9.50525 5.92898L9.50316 5.93225L8.81515 7.05178H6.04059C5.62452 7.05185 5.30361 7.37367 5.279 7.76552L5.27832 7.77327V7.78732C5.27832 8.21445 5.62907 8.53631 6.02731 8.53631H6.41433L7.43393 17.0162C7.54848 17.9811 8.36638 18.7004 9.33064 18.7004H14.4394C15.4014 18.7004 16.2228 17.982 16.3231 17.0129L17.3423 8.5364H17.7293C18.1565 8.5364 18.4783 8.18566 18.4783 7.78741C18.4783 7.36028 18.1276 7.03843 17.7293 7.03843H14.9404L14.2552 5.93522C14.0144 5.53075 13.5769 5.2998 13.1154 5.2998H10.6412ZM6.50298 8.43631L6.40374 8.44825L6.41433 8.53631H6.50303V8.43631H6.50298ZM8.81515 7.05178H8.87106V7.15178L8.78587 7.09942L8.81515 7.05178ZM10.5732 7.05178L10.7319 6.79769H13.0368L13.1917 7.05178H10.5732ZM8.9136 16.8407L7.92995 8.53633H15.853L14.857 16.8357C14.8255 17.0444 14.6459 17.2025 14.4394 17.2025H9.33062C9.10862 17.2025 8.93462 17.0436 8.91409 16.8451L8.9136 16.8407ZM11.4155 15.8096L11.0412 10.3548C11.0146 9.92334 10.6371 9.63108 10.2451 9.65527C9.81269 9.6811 9.5201 10.0595 9.54456 10.452L9.5447 10.4542L9.9191 15.9096C9.94469 16.3095 10.2732 16.6091 10.6673 16.6091L10.7019 16.6093L10.7159 16.6085C11.1189 16.5831 11.4404 16.2337 11.4157 15.813L11.4155 15.8096ZM13.5228 9.65523C13.1025 9.63061 12.7531 9.95146 12.7274 10.3542L12.3528 15.813C12.3282 16.2336 12.6496 16.5833 13.0528 16.6085L13.0605 16.6091H13.1012C13.4954 16.6091 13.8239 16.309 13.8495 15.9093L14.224 10.4517C14.2487 10.0311 13.9273 9.68046 13.5241 9.65531L13.5228 9.65523ZM13.5293 9.75593L13.5241 9.65531L13.4074 9.75766C13.4432 9.75383 13.4798 9.75288 13.517 9.75507L13.518 9.75513C13.5218 9.75536 13.5255 9.75563 13.5293 9.75593ZM13.8063 15.0794L13.8495 15.9093L13.7747 15.5393L13.8063 15.0794ZM12.8023 10.725L12.7274 10.3542L12.8436 10.2523C12.8352 10.2874 12.8297 10.3236 12.8273 10.3606L12.8273 10.3614L12.8023 10.725Z"
        fill="#1F1F1F"
      />
    </svg>
  );
}
