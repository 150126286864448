import { FC } from 'react';
import Link from 'next/link';

import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Avatar } from 'components/atoms/Avatar';
import { DepartmentTag } from 'components/atoms/DepartmentTag';
import dayjs from 'dayjs';
import { EmployeeInCompany } from 'lib/types/company';
import { PersonalDetail } from 'components/atoms/PersonalDetail';
import { SocialHandlersIcons } from 'components/molecules/SocialIcons';
import EmployeeLocation from '../EmployeeLocation';
import { Location } from '../../../lib/contexts/hr/company/domain/Location/Location';

interface IPersonalDetailsModal {
  selectedEmployee: EmployeeInCompany;
  showModal: boolean;
  handleCloseModal: () => void;
  canShowProfile?: boolean;
}

const PersonalDetailsModal: FC<IPersonalDetailsModal> = ({
  canShowProfile,
  selectedEmployee,
  showModal,
  handleCloseModal,
}) => {
  return (
    <Transition
      className="fixed top-0 left-0 w-full min-h-screen z-50"
      show={showModal}
      enter="transition ease-out duration-100 transform"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-100 transform"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <style jsx global>{`
        html {
          overflow: hidden;
        }
      `}</style>
      <div className="flex items-center justify-center w-full min-h-screen fixed top-0 left-0 bg-black bg-opacity-70 z-50">
        <div className="relative bg-white rounded-lg py-12 px-8 w-100">
          <button className="absolute right-5 top-5" onClick={handleCloseModal}>
            <XIcon className="w-5" />
          </button>
          <div className="flex items-center gap-5">
            <div className="">
              <Avatar
                first_name={selectedEmployee?.first_name}
                last_name={selectedEmployee?.last_name}
                size="large"
              />
            </div>
            <div className="flex flex-col justify-start items-start gap-2">
              <h2 className="font-bold text-2xl">
                {`${selectedEmployee?.first_name} ${selectedEmployee?.last_name}`}
              </h2>
              <p className="text-gray-600">
                {selectedEmployee?.job_title}{' '}
                <DepartmentTag
                  label={selectedEmployee?.department?.name}
                  size="small"
                  className="ml-1"
                  color={selectedEmployee?.department?.color}
                />
                <span className="text-xs ml-2 bg-gray-200 rounded-md px-2 py-0.5">
                  {dayjs(selectedEmployee?.start_date).format('DD/MM/YYYY')}
                </span>
              </p>
              {selectedEmployee?.user_location && (
                <EmployeeLocation
                  location={Location.fromPrisma({
                    ...selectedEmployee?.user_location,
                    id: '',
                    company_id: '',
                  })}
                />
              )}
            </div>
          </div>
          <hr className="my-10 border-gray-200" />
          <div className="flex flex-col justify-between relative  w-full h-full z-10">
            <div>
              <div className="text-2xl font-bold mb-6">Contact</div>
              <PersonalDetail
                label="Work email"
                value={selectedEmployee?.corporate_email}
                capitalize={false}
              />
              <SocialHandlersIcons
                personal_linkedin={selectedEmployee?.personal_linkedin}
                personal_twitter={selectedEmployee?.personal_twitter}
              />
            </div>

            {canShowProfile && (
              <Link
                href={`/profile?employee_id=${selectedEmployee.id}&callback=org_chart`}
                className="pt-10 text-sm text-brand-green-900 font-medium"
              >
                View full profile
              </Link>
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default PersonalDetailsModal;
