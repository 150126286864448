import { LocationTypeEnum } from '../../../lib/contexts/hr/company/domain/Location/LocationType';
import CompanyIcon from '../../../lib/icons/company';
import clx from 'classnames';
import GlobeIcon from '../../../lib/icons/globe';
import CommandIcon from '../../../lib/icons/command';

export default function LocationTypeChip({
  className,
  locationType,
}: {
  className?: string;
  locationType: LocationTypeEnum;
}) {
  return (
    <div
      className={clx(
        'flex justify-start items-center text-brand-gray-900',
        className,
      )}
    >
      {locationType === LocationTypeEnum.OFFICE && (
        <>
          <CompanyIcon
            color={'#868686'}
            className="w-5 h-5 mr-2"
            sizeH={24}
            sizeW={24}
          />
          <span className="text-sm">Office based</span>
        </>
      )}
      {locationType === LocationTypeEnum.REMOTE && (
        <>
          <GlobeIcon color={'#868686'} className="mr-2" />
          <span className="text-sm">Remote</span>
        </>
      )}
      {locationType === LocationTypeEnum.HYBRID && (
        <>
          <CommandIcon color={'#868686'} className="mr-2" />
          <span className="text-sm">Hybrid</span>
        </>
      )}
    </div>
  );
}
