import React, { FC } from 'react';
import clx from 'classnames';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

interface PageSizeSelectorProps {
  pageSize: number;
  onChange: (pageSize: number) => void;
  options?: number[];
}

const sizeOptionsDefault = [10, 25, 50, 100];

const PageSizeSelector: FC<PageSizeSelectorProps> = ({
  pageSize,
  onChange,
  options = sizeOptionsDefault,
}) => {
  return (
    <div className="flex items-center">
      <div className="font-bold text-gray-400 text-sm mr-5">
        Results per page
      </div>
      <div>
        <Popover className="relative">
          <Popover.Button className="flex items-center font-bold px-3 py-1 rounded-md border border-gray-300">
            {pageSize} <ChevronDownIcon className="w-4 ml-2" />
          </Popover.Button>

          <Transition
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 translate-y-2"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-2"
          >
            <Popover.Panel className="absolute z-10 transform -translate-y-52">
              {({ close }) => (
                <div className="grid grid-cols-1 bg-white shadow-md w-32 rounded-md">
                  {options.map((size) => (
                    <button
                      key={size}
                      onClick={() => {
                        onChange(size);
                        close();
                      }}
                      className={clx('text-left py-2 px-4 hover:bg-gray-100', {
                        'bg-brand-primary-500 hover:bg-brand-primary-500 text-white':
                          pageSize === size,
                      })}
                    >
                      {size}
                    </button>
                  ))}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </div>
  );
};

export default PageSizeSelector;
