'use client';
import Dialog from '../../../../organisms/Dialog';
import { Department } from '../../../../../lib/contexts/hr/company/Department';
import { Input } from '../../../../atoms/Input';
import { useState } from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import clx from 'classnames';
import { Button } from '../../../../atoms/Button';

export function DeleteDepartmentModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <div className="font-bold text-2xl mb-8">
          Need to delete a department?
        </div>
        <div className="mt-2">
          Deleting a department requires a little behind-the-scenes work from
          our team.
        </div>
        <div className="mt-2">
          Simply drop us a message using the chat box in the bottom-right
          corner, and we’ll get everything sorted right away.
        </div>
        <div>
          <Button type="flat" className="mt-8 w-full" onClick={onClose}>
            Close
          </Button>
        </div>
      </Dialog>
    </>
  );
}
