import { FC } from 'react';
import clx from 'classnames';
import { randomColors as randomColorsGlobal } from '@/lib/utils/randomColors';
import { Department } from '@/lib/contexts/hr/company/Department';

type DepartmentTagProps = {
  label: string;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  color?: string;
};
function randomColors(label: string): string {
  return randomColorsGlobal(label);
}

export const DepartmentTag: FC<DepartmentTagProps> = ({
  label,
  className,
  size = 'medium',
  color,
}) => {
  return (
    <span
      className={clx(
        'rounded-md px-2 py-1 uppercase',
        color ?? randomColors(label),
        {
          'text-xs py-0.5 px-1': size === 'small',
          'text-sm': size === 'medium',
          'text-base  ': size === 'large',
        },
        className,
      )}
    >
      {label}
    </span>
  );
};
