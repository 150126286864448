import { ReactNode } from 'react';
import clx from 'classnames';

export default function MenuGrid({
  children,
  cols = 3,
}: {
  children: ReactNode;
  cols?: 3 | 4;
}) {
  return (
    <div
      className={clx('w-full grid grid-cols-1 gap-4', {
        'md:grid-cols-3': cols === 3,
        'md:grid-cols-4': cols === 4,
      })}
    >
      {children}
    </div>
  );
}
