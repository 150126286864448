import { IconProps } from '../types';

export default function PencilIcon(props: IconProps) {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.76965 1.16961L11.5249 2.8064C12.2519 3.48438 12.2917 4.62339 11.6137 5.35043L5.16532 12.2655C4.94196 12.505 4.64169 12.6587 4.31675 12.6997L1.45617 13.0608C0.864402 13.1355 0.324121 12.7163 0.250046 12.1294L0.241525 12.0257L0.241211 11.9859L0.403265 9.05031C0.421516 8.72331 0.553833 8.41305 0.777195 8.17353L7.22561 1.25844C7.9036 0.531397 9.0426 0.491622 9.76965 1.16961ZM2.50976 9.2475L2.38805 9.37865L2.30101 10.9383L3.85076 10.7426L3.99009 10.5929C3.37352 10.2092 2.87288 9.77728 2.50976 9.2475ZM6.60133 4.86008L3.95421 7.69803L4.00857 7.83958C4.18157 8.22793 4.5251 8.56542 5.03102 8.88461L5.22834 9.00351C5.28155 9.03419 5.33086 9.06902 5.37612 9.10736L8.08081 6.21094C7.66751 5.82911 7.08909 5.32252 6.60133 4.86008ZM8.55192 2.76871L7.7873 3.58434C8.11338 3.92362 8.75935 4.59266 9.1698 5.03805L10.0146 4.13271L8.55192 2.76871Z"
        fill="#1F1F1F"
      />
    </svg>
  );
}
