export default class AdminPayrollConfig {
  companyId: string;
  governmentGatewayUserId?: string;
  governmentGatewayPassword?: string;
  accountOfficeReference?: string;

  constructor({
    companyId,
    governmentGatewayUserId,
    governmentGatewayPassword,
    accountOfficeReference,
  }: {
    companyId: string;
    governmentGatewayUserId?: string;
    governmentGatewayPassword?: string;
    accountOfficeReference?: string;
  }) {
    this.companyId = companyId;
    this.governmentGatewayUserId = governmentGatewayUserId;
    this.governmentGatewayPassword = governmentGatewayPassword;
    this.accountOfficeReference = accountOfficeReference;
  }

  static fromJson(data: any): AdminPayrollConfig {
    return new AdminPayrollConfig({
      companyId: data.companyId,
      governmentGatewayUserId: data.governmentGatewayUserId,
      governmentGatewayPassword: data.governmentGatewayPassword,
      accountOfficeReference: data.accountOfficeReference,
    });
  }
}
