'use client';
import Dialog from '../../../../organisms/Dialog';
import { Department } from '../../../../../lib/contexts/hr/company/Department';
import { Input } from '../../../../atoms/Input';
import { useState } from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import clx from 'classnames';
import { Button } from '../../../../atoms/Button';

export function EditDepartmentModal({
  department,
  open,
  onClose,
}: {
  department: Department;
  open: boolean;
  onClose: () => void;
}) {
  const [inputDepartment, setInputDepartment] = useState<string>(
    department.name ?? '',
  );
  const [inputColor, setInputColor] = useState<string>(department.color ?? '');
  const [loading, setLoading] = useState<boolean>(false);

  const onSave = async () => {
    if (inputDepartment && inputDepartment.trim() !== '') {
      setLoading(true);
      await fetch('/api/v1/company/save-department', {
        method: 'POST',
        body: JSON.stringify({
          id: department.id,
          color: inputColor,
          name: inputDepartment.trim(),
        }),
      });
      onClose();
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <div className="font-bold text-2xl mb-8">Edit department</div>
        <div className="mt-2">
          <Input
            label="Name"
            value={inputDepartment}
            onChange={(e) => setInputDepartment(e.target.value)}
          />
          <div className="mt-4">
            <div className="text-sm font-semibold">Colors</div>
            <div className="flex justify-stretch flex-wrap gab-2">
              {Department.COLORS.map((color) => {
                return (
                  <div
                    key={color}
                    className={`w-6 h-6 m-3 rounded-full ${color} cursor-pointer flex items-center justify-center`}
                    onClick={() => setInputColor(color)}
                  >
                    {color === inputColor && (
                      <CheckIcon className={clx('w-5 h-5 font-bold')} />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <Button disabled={loading} className="mt-8 w-full" onClick={onSave}>
            Save
          </Button>
        </div>
      </Dialog>
    </>
  );
}

function forceGenerateColors() {
  return (
    <div
      className="bg-pink-200 text-pink-700 bg-red-200 text-red-700',
    bg-yellow-200 text-yellow-700
    bg-blue-100 text-blue-700
    bg-green-100 text-green-700
    bg-flamingo-100 text-flamingo-700
    bg-quicksand-100 text-quicksand-700
    bg-mongoose-100 text-mongoose-700
    bg-mountbatten-pink-100 text-mountbatten-pink-900
    bg-whiskey-100 text-whiskey-900
    bg-persian-green-100 text-persian-green-900
    bg-hippie-blue-100 text-hippie-blue-900
    bg-ship-cove-100 text-ship-cove-900
    bg-lavender-100 text-lavender-900
    bg-light-wisteria-100 text-light-wisteria-900
    bg-trendy-pink-100 text-trendy-pink-900
    bg-hot-pink-100 text-hot-pink-900 bg-cranberry-100 text-cranberry-900 bg-olive-drab-100 text-olive-drab-900 bg-aspargus-100 text-aspargus-900 bg-crimson-100 text-crimson-900 bg-slate-gray-100 text-slate-gray-900"
    ></div>
  );
}
