import React, { FC } from 'react';
import clx from 'classnames';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

interface PageNavigatorProps {
  currentPage: number;
  onChange: (page: number) => void;
  totalPages: number;
}

const PageNavigator: FC<PageNavigatorProps> = ({
  currentPage,
  onChange,
  totalPages,
}) => {
  return (
    <div className="flex items-center">
      <button
        onClick={() => onChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ChevronLeftIcon
          className={clx('w-5 mr-2 cursor-pointer', {
            'opacity-50 cursor-not-allowed': currentPage === 1,
          })}
        />
      </button>
      {[...Array(totalPages)].map((_, i) => (
        <button
          key={i}
          onClick={() => onChange(i + 1)}
          disabled={currentPage === i + 1}
          className={clx('rounded-md w-6 h-6', {
            'text-white bg-black': currentPage === i + 1,
            'mr-2': i !== totalPages - 1,
          })}
        >
          {i + 1}
        </button>
      ))}
      <button
        onClick={() => onChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <ChevronRightIcon
          className={clx('w-5 ml-2 cursor-pointer', {
            'opacity-50 cursor-not-allowed': currentPage === totalPages,
          })}
        />
      </button>
    </div>
  );
};

export default PageNavigator;
