import {
  FC,
  useState,
  useEffect,
  ReactNode,
  useRef,
  MutableRefObject,
} from 'react';
import clx from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { LoadingSpinner } from '../LoadingSpinner';
import { useOutsideClick } from '../../../lib/utils/on-click-outside-component';

type SelectValue = string | number | boolean;

export type SelectOption = {
  key: string | number;
  value: SelectValue;
  disabled?: boolean;
  icon?: ReactNode;
};

export type AutocompleteProps = {
  onSelect: (value: SelectOption) => void;
  options: SelectOption[];
  label: string;
  striped?: boolean;
  RightIcon?: any;
  LeftReactNode?: ReactNode;
  value?: SelectOption;
  showLabel?: boolean;
  className?: string;
  border?: boolean;
  textCenter?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  onInputChange?: (value: string) => void;
  autocompleteInputValue?: string;
};

export const Autocomplete: FC<AutocompleteProps> = ({
  RightIcon,
  LeftReactNode,
  striped = true,
  showLabel = false,
  value,
  isLoading,
  onInputChange,
  autocompleteInputValue,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputKey, setInputKey] = useState(value?.key);
  const [inputHasBeenEdited, setInputHasBeenEdited] = useState(
    !!autocompleteInputValue,
  );
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const border = props.border ?? true;

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsFocused(false));

  const selectedIcon = props.options.find((o) => o.key === inputKey)?.icon;

  useEffect(() => {
    if (value) {
      if (autocompleteInputValue === undefined) {
        setInputValue(value?.value?.toString());
      } else {
        setInputValue(autocompleteInputValue);
      }
      setInputKey(value?.key);
    }
  }, [value]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const newFilteredOptions = props.options?.filter((option) =>
      option.value.toString().toLowerCase().includes(inputValue?.toLowerCase()),
    );

    if (!newFilteredOptions || newFilteredOptions.length === 0) {
      return;
    }

    if (
      newFilteredOptions.length === 1 &&
      newFilteredOptions[0]?.key === inputKey &&
      !inputHasBeenEdited
    ) {
      setFilteredOptions(props.options);
    } else {
      setFilteredOptions(newFilteredOptions);
    }
  }, [inputValue, props.options]);

  return (
    <div
      ref={wrapperRef}
      className={clx('relative min-h-10 max-h-[46px]', props.className)}
    >
      {showLabel && inputValue !== '' && (
        <div className="absolute -top-3 left-2 bg-white text-gray-400 px-1 text-sm">
          {props.label}
        </div>
      )}
      <div
        className={clx(
          'text-sm cursor-pointer p-3 w-full rounded-md focus:no-underline flex items-center',
          {
            'border border-gray-300': border,
            'text-center': props.textCenter,
          },
        )}
      >
        {LeftReactNode}
        {selectedIcon && <span className="mr-2">{selectedIcon}</span>}
        <input
          className="w-full text-sm outline-none bg-transparent"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            setInputHasBeenEdited(true);
            if (onInputChange) {
              onInputChange(e.target.value);
            }
          }}
          placeholder={props.placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setTimeout(() => {
              setIsFocused(false);
            }, 300);
          }}
        />
        {RightIcon ? (
          <RightIcon
            onClick={() => setIsFocused(true)}
            className="absolute right-2 top-4 text-gray-400 w-4 h-4"
          />
        ) : (
          <ChevronDownIcon
            onClick={() => setIsFocused(true)}
            className="absolute right-2 top-4 text-gray-400 w-4 h-4"
          />
        )}
      </div>
      {isFocused && (
        <>
          <div className="absolute bg-white w-full mt-2 shadow-md max-h-96 overflow-auto z-50">
            {filteredOptions.length === 0 && (
              <div className="absolute bg-white w-full mt-2 shadow-md max-h-96 overflow-auto z-20 text-sm ">
                <div
                  className={clx(
                    'px-4 py-2 text-sm cursor-pointer hover:bg-brand-primary-500 transition ease-out duration-200',
                  )}
                >
                  No results found
                </div>
              </div>
            )}
            {filteredOptions.length > 0 && (
              <>
                {filteredOptions.map((option, i) => (
                  <button
                    key={option.key}
                    onClick={() => {
                      props.onSelect(option);
                      setInputKey(option.key);
                      setInputValue(option.value.toString());
                    }}
                    className={clx(
                      'w-full px-4 py-2 text-sm cursor-pointer hover:bg-brand-primary-500 transition ease-out duration-200',
                      {
                        'bg-brand-gray-200': i % 2 !== 0 && striped,
                      },
                    )}
                  >
                    <span className="flex items-center">
                      {option.icon}
                      <span className="mx-4">{option.value}</span>
                    </span>
                  </button>
                ))}
              </>
            )}
            {isLoading && (
              <div className="flex justify-center items-center m-4">
                <LoadingSpinner size="sm" />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
