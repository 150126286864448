import { ReactNode } from 'react';
import clx from 'classnames';
export default function FilterCard({
  text,
  filters,
  active,
  className,
  intercomId,
}: {
  text: string;
  filters: ReactNode;
  active: boolean;
  className?: string;
  intercomId?: string;
}) {
  return (
    <div
      data-intercom-target={intercomId}
      className={clx(
        'flex justify-between items-center w-full h-[56px] p-6 rounded-[4px]',
        className,
        {
          ['bg-brand-primary-100']: active,
          ['bg-brand-gray-100']: !active,
        },
      )}
    >
      <div
        className={clx({
          ['text-brand-primary-900']: active,
          ['text-brand-gray-900']: !active,
        })}
      >
        {text}
      </div>
      <div>{filters}</div>
    </div>
  );
}
