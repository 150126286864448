import {
  useState,
  useEffect,
  useRef,
  FC,
  InputHTMLAttributes,
  FocusEvent,
  MutableRefObject,
  ChangeEvent,
} from 'react';
import { Transition } from '@headlessui/react';
import clx from 'classnames';
import { ColorScheme } from 'lib/theme';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  colorScheme?: ColorScheme;
  className?: string;
  containerStyleOverrides?: React.CSSProperties | undefined;
  labelStyleOverrides?: React.CSSProperties | undefined;
  ref?: MutableRefObject<any>;
  inputClassName?: string;
  height?: string;
  onPressEnter?: () => void;
}

type InputColors = string;
type LabelColors = string;

function noop(e: ChangeEvent<any>) {}

function getColorSchemeClasses(
  colorScheme: ColorScheme,
): [InputColors, LabelColors] {
  switch (colorScheme) {
    case 'success':
      return ['border-green-400', 'text-green-500'];
    case 'error':
      return ['border-red-400', 'text-red-500'];
    case 'warning':
      return ['border-yellow-400', 'text-yellow-500'];
    case 'dark':
      return ['border-gray-900', 'text-black'];
    case 'light':
      return ['border-white', 'text-white'];
    case 'gray':
      return ['border-gray-300', 'text-gray-400'];
    case 'info':
    case 'default':
    default:
      return ['border-brand-primary-400', 'text-gray-400'];
  }
}
// TODO: We need to create more input components or use a new component library for
// Components such as `Checkbox`, `Label`, `Textarea` and `Radio`
export const Input: FC<InputProps> = ({
  colorScheme,
  containerStyleOverrides,
  label,
  labelStyleOverrides,
  onBlur,
  onChange,
  className,
  inputClassName,
  onPressEnter,
  ...props
}) => {
  const [isWriting, setIsWriting] = useState(false);
  const [inputColors, labelColors] = useState(
    colorScheme ? getColorSchemeClasses(colorScheme) : undefined,
  );
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current?.value) {
      setIsWriting(true);
    }
  }, [isWriting, props]);

  function handleOnBlur(event: FocusEvent<HTMLInputElement>) {
    if (!inputRef.current?.value) {
      setIsWriting(false);
    }

    if (onBlur) {
      onBlur(event);
    }
  }

  const handleKeyDown = (e: any) => {
    if (onPressEnter && e.code === 'Enter') {
      onPressEnter();
    }
  };

  return (
    <div className="relative" style={containerStyleOverrides}>
      {label && props.type !== 'checkbox' && (
        <Transition
          show={isWriting}
          enter="transition ease-in-out duration-200 transform"
          enterFrom="opacity-0 translate-y-4"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in-out duration-2  00 transform"
          leaveFrom="opacity-100 translate-y-4"
          leaveTo="opacity-0 translate-y-0"
        >
          <label
            className={clx(
              'absolute -top-3 left-3 transition-all bg-white px-1 text-sm',
              labelColors,
            )}
            style={labelStyleOverrides}
          >
            {label}
          </label>
        </Transition>
      )}
      <input
        className={clx(
          'py-2 px-2 border border-gray-300 rounded-md focus:outline-none',
          {
            'placeholder-white': isWriting,
            'min-h-10': !props.height,
            [props.height as string]: props.height,
            [inputColors?.toString() as string]: isWriting,
            'accent-black cursor-pointer': props.type === 'checkbox',
            [inputClassName as string]:
              props.type === 'checkbox' && inputClassName,
            'h-full w-full': props.type !== 'checkbox',
          },
          className,
        )}
        {...props}
        ref={inputRef}
        onFocus={() => setIsWriting(true)}
        onBlur={handleOnBlur}
        onChange={onChange ?? noop}
        onKeyDown={handleKeyDown}
      />
      {props.type === 'checkbox' && label && (
        <span className="ml-4 font-medium">{label}</span>
      )}
    </div>
  );
};
