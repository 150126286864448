import { uuidv4 } from '@firebase/util';
import { seededRandom } from '../../../utils';
import { colorSchemes } from '@/lib/utils/randomColors';

export class Department {
  public readonly id: string;
  public readonly name: string;
  color: string;

  static COLORS = colorSchemes;
  constructor({
    id,
    name,
    color,
  }: {
    id: string;
    name: string;
    color: string;
  }) {
    this.id = id;
    this.name = name;
    this.color = color ?? Department.getDefaultColor(name);
  }

  static fromJson(data: any) {
    return new Department({
      id: data.id,
      name: data.name,
      color: data.color,
    });
  }

  static create({ name }: { name: string }): Department {
    return new Department({
      id: uuidv4().toString(),
      name,
      color: Department.getDefaultColor(name),
    });
  }

  static getDefaultColor(label: string): string {
    return Department.COLORS[
      seededRandom(label, 0, Department.COLORS.length - 1)
    ];
  }
}

export const defaultDepartments = [
  Department.create({ name: 'Management' }),
  Department.create({ name: 'Engineering' }),
  Department.create({ name: 'Product' }),
  Department.create({ name: 'Design' }),
  Department.create({ name: 'Sales' }),
  Department.create({ name: 'Marketing' }),
  Department.create({ name: 'Customer Support' }),
  Department.create({ name: 'People' }),
  Department.create({ name: 'Finance' }),
  Department.create({ name: 'Legal' }),
  Department.create({ name: 'Internal Communications' }),
];
