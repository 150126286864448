export default function GlobeIcon({
  color,
  className,
  size = 24,
  viewBox = '0 0 24 24',
}: {
  color?: string;
  className?: string;
  size?: number;
  viewBox?: string;
}) {
  const colorClass = color ? color : '#333333';
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox={viewBox}
        fill={colorClass}
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0013 19.3332C16.0514 19.3332 19.3346 16.0499 19.3346 11.9998C19.3346 7.94975 16.0514 4.6665 12.0013 4.6665C7.95121 4.6665 4.66797 7.94975 4.66797 11.9998C4.66797 16.0499 7.95121 19.3332 12.0013 19.3332ZM10.4851 6.19306C8.11384 6.81048 6.31331 8.842 6.03792 11.3332H8.70136C8.85083 9.49105 9.46737 7.72278 10.4851 6.19306ZM13.5175 6.19306C14.5352 7.72278 15.1518 9.49105 15.3012 11.3332H17.9647C17.6893 8.842 15.8888 6.81048 13.5175 6.19306ZM13.9629 11.3332C13.7954 9.52439 13.1144 7.80113 12.0013 6.36703C10.8882 7.80113 10.2072 9.52439 10.0397 11.3332H13.9629ZM10.0397 12.6665H13.9629C13.7954 14.4753 13.1144 16.1985 12.0013 17.6326C10.8882 16.1985 10.2072 14.4753 10.0397 12.6665ZM8.70136 12.6665H6.03792C6.31331 15.1577 8.11384 17.1892 10.4851 17.8066C9.46737 16.2769 8.85083 14.5086 8.70136 12.6665ZM13.5175 17.8066C14.5352 16.2769 15.1518 14.5086 15.3012 12.6665H17.9647C17.6893 15.1577 15.8888 17.1892 13.5175 17.8066Z"
        />
      </svg>
    </>
  );
}
