import { FC } from 'react';
import clx from 'classnames';

type BadgeProps = {
  children: React.ReactNode;
  pill?: boolean;
  size?: 'medium' | 'large' | 'small';
  bg?: string;
  text?: string;
};

export const Badge: FC<BadgeProps> = ({
  bg = 'gray-200',
  text = 'gray-700',
  children,
  pill = false,
  size = 'medium',
}) => {
  return (
    <span
      className={clx(`px-2 py-1 max-w-max h-fit bg-${bg} text-${text}`, {
        'text-xs px-1 py-0.5': size === 'small',
        'text-sm': size === 'medium',
        'text-base': size === 'large',
        'rounded-md': !pill,
        'rounded-full': pill,
      })}
    >
      {children}
    </span>
  );
};
