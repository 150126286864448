import { useCallback, useEffect, useRef, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { generateButton, generateNodeContent } from './html';
import * as d3 from 'd3';
import detectBrowser from 'lib/utils/detectBrowser';
import { LoadingSpinner } from 'components/atoms/LoadingSpinner';
import { Company } from 'lib/contexts/hr/company/domain/Company';
import { EmployeeInCompany } from 'lib/types/company';

export function getParentId(
  employees: EmployeeInCompany[],
  company: Company,
  employee: EmployeeInCompany,
) {
  // @ts-ignore
  if (!employee.parentId || employee.line_manager_id === null) {
    return company.id;
  }

  const ids = employees.map((_employee) => _employee.id);
  const finded = ids.includes(employee.line_manager_id);
  if (!finded) {
    return company.id;
  }

  if (employee.id === employee.line_manager_id) {
    return company.id;
  }

  const emoloyeeLineManager = employees.find(
    (_employee) => _employee.id === employee.line_manager_id,
  );

  if (
    emoloyeeLineManager &&
    emoloyeeLineManager.line_manager_id === employee.id
  ) {
    return company.id;
  }

  return employee.line_manager_id;
}

export function getOrgChartEmployeesData(
  employees: EmployeeInCompany[],
  company,
) {
  return (employees || []).map((employee) => {
    const parentId = getParentId(employees, company, employee);
    return {
      ...employee,
      parentId,
      alert: parentId === company.id && employee.line_manager_id !== null,
    };
  });
}

export const EmployeesChart = ({
  chart,
  employees,
  currentUser,
  showDetails,
  company,
}) => {
  const orgChartContainerRef = useRef(null);
  const [browser, setBrowser] = useState(detectBrowser());
  const [loading, setLoading] = useState<boolean>();
  const employeesData = getOrgChartEmployeesData(employees, company);

  chart.onButtonClick = function (event, d) {
    const attrs = this.getChartState();
    if (attrs.setActiveNodeCentered) {
      d.data._centered = true;
      d.data._centeredWithDescendants = true;
    }

    // If childrens are expanded
    if (d.children) {
      // Collapse them
      d._children = d.children;
      d.children = null;

      // Set descendants expanded property to false
      this.setExpansionFlagToChildren(d, false);
    } else {
      // Expand children
      d.children = d._children;
      d._children = null;

      // Set each children as expanded
      if (d.children) {
        d.children.forEach(({ data }) => (data._expanded = true));
      }
    }

    // Redraw Graph
    this.update(d);
    if (d.children) {
      this.setCentered(d.id).fit();
    } else {
      this.setCentered(d.id).fit({
        scale: false,
      });
    }

    event.stopPropagation();
  };

  const renderChart = () => {
    return chart
      .container(orgChartContainerRef.current)
      .data([
        {
          id: company.id,
          first_name: company.name,
          last_name: '',
          parent_id: null,
          line_manager_id: null,
        },
        ...employeesData,
      ])
      .svgHeight(600)
      .childrenMargin(() => 150)
      .compactMarginBetween(() => 65)
      .compactMarginPair(() => 100)
      .neightbourMargin(() => 50)
      .siblingsMargin(() => 100)
      .compact(false)
      .initialZoom(0.8)
      .nodeWidth(() => 236)
      .nodeHeight(() => (browser === 'Safari' ? 132 : 102))
      .linkUpdate(function (d: any) {
        d3.select(this)
          .attr('stroke', (d: any) =>
            d.data._upToTheRootHighlighted ? '#152785' : '#00835A',
          )
          .attr('stroke-width', (d: any) =>
            d.data._upToTheRootHighlighted ? 5 : 1.5,
          );

        if (d.data._upToTheRootHighlighted) {
          d3.select(this).raise();
        }
      })
      .onNodeClick(showDetails)
      .buttonContent(generateButton)
      .nodeContent(generateNodeContent(currentUser.id, browser))
      .render();
  };

  useEffect(() => {
    if (orgChartContainerRef.current && employeesData.length > 0 && company) {
      setLoading(false);
      renderChart();
    } else {
      setLoading(true);
    }
  }, [employees, employeesData, company, orgChartContainerRef.current]);

  return (
    <section>
      {!loading ? (
        <div
          className={browser === 'Safari' ? 'relative' : ''}
          ref={orgChartContainerRef}
        ></div>
      ) : (
        <section className="h-[600px] w-full flex justify-center items-center">
          <LoadingSpinner />
        </section>
      )}
    </section>
  );
};
