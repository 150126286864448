import { FC } from 'react';
import { countryCodeToEmoji } from 'lib/utils';
import { Location } from '../../../lib/contexts/hr/company/domain/Location/Location';
import { LocationTypeEnum } from '../../../lib/contexts/hr/company/domain/Location/LocationType';
import GlobeIcon from '../../../lib/icons/globe';
import CompanyIcon from '../../../lib/icons/company';
import clx from 'classnames';
import CommandIcon from '../../../lib/icons/command';

const EmployeeLocation: FC<{
  location?: Location;
  iconSize?: 'sm';
  textSize?: string;
  className?: string;
}> = ({ location, textSize, iconSize, className }) => {
  if (!location) {
    return <></>;
  }

  return (
    <div
      className={clx(' mt-1 flex items-center', className, {
        'text-base': !textSize,
        [textSize]: !!textSize,
        'text-brand-gray-900': !className?.includes('text-gray-'),
      })}
    >
      <span className="mr-2">
        {location?.type === LocationTypeEnum.REMOTE && (
          <GlobeIcon
            className="mt-[-2px]"
            size={iconSize === 'sm' ? 23 : undefined}
            color="#868686"
          />
        )}
        {location?.type === LocationTypeEnum.HYBRID && (
          <CommandIcon
            className="mt-[-2px]"
            color="#868686"
            size={iconSize === 'sm' ? 20 : undefined}
          />
        )}
        {location?.type === LocationTypeEnum.OFFICE && (
          <CompanyIcon
            className="mt-[-3px]"
            color="#868686"
            size={iconSize === 'sm' ? 18 : undefined}
            viewBox={iconSize === 'sm' ? '0 0 22 22' : undefined}
          />
        )}
      </span>
      {location?.city} {countryCodeToEmoji(location?.country?.code)}
    </div>
  );
};

export default EmployeeLocation;
