import { seededRandom } from '@/lib/utils/index';

export const colorSchemes = [
  'bg-pink-200',
  'bg-red-200',
  'bg-yellow-200',
  'bg-blue-100',
  'bg-green-100',
  'bg-flamingo-100',
  'bg-quicksand-100',
  'bg-mongoose-100',
  'bg-mountbatten-pink-100',
  'bg-whiskey-100',
  'bg-persian-green-100',
  'bg-hippie-blue-100',
  'bg-ship-cove-100',
  'bg-lavender-100',
  'bg-light-wisteria-100',
  'bg-trendy-pink-100',
  'bg-hot-pink-100',
  'bg-cranberry-100',
  'bg-olive-drab-100',
  'bg-aspargus-100',
  'bg-crimson-100',
  'bg-slate-gray-100',
];

export const bgTextColorSchemes = [
  'bg-pink-200 text-pink-900 fill-pink-900',
  'bg-red-200 text-red-900 fill-red-900',
  'bg-yellow-200 text-yellow-900 fill-yellow-900',
  'bg-blue-100 text-blue-900 fill-blue-900',
  'bg-green-100 text-green-900 fill-green-900',
  'bg-flamingo-100 text-flamingo-900',
  'bg-quicksand-100 text-quicksand-900',
  'bg-mongoose-100 text-mongoose-900',
  'bg-mountbatten-pink-100 text-mountbatten-pink-900',
  'bg-whiskey-100 text-whiskey-900',
  'bg-persian-green-100 text-persian-green-900',
  'bg-hippie-blue-100 text-hippie-blue-900',
  'bg-ship-cove-100 text-ship-cove-900',
  'bg-lavender-100 text-lavender-900',
  'bg-light-wisteria-100 text-light-wisteria-900',
  'bg-trendy-pink-100 text-trendy-pink-900',
  'bg-hot-pink-100 text-hot-pink-900',
  'bg-cranberry-100 text-cranberry-900',
  'bg-olive-drab-100 text-olive-drab-900',
  'bg-aspargus-100 text-aspargus-900',
  'bg-crimson-100 text-crimson-900',
  'bg-slate-gray-100 text-slate-gray-900',
];
export function randomColors(label: string): string {
  return colorSchemes[seededRandom(label, 0, colorSchemes.length - 1)];
}
export function randomBgAndTextColors(label: string): string {
  return bgTextColorSchemes[
    seededRandom(label, 0, bgTextColorSchemes.length - 1)
  ];
}
