import { FC, useCallback, useMemo, useState } from 'react';
import { ChevronDownIcon, XIcon } from '@heroicons/react/outline';

import { Avatar } from 'components/atoms/Avatar';
import { EmployeeInCompany } from 'lib/types/company';
import {
  EmployeesChart,
  getOrgChartEmployeesData,
} from 'components/organisms/EmployeesChart';
import Tooltip from 'components/atoms/Tooltip';
import PersonalDetailsModal from 'components/molecules/PersonalDetailsModal';
import { UserProfileDataResponse } from 'lib/prisma/queries/user/get-user-profile-data';
import { isLineManager } from 'lib/utils/user';
import { useSession } from 'lib/hooks/session';
import { AccountType } from 'lib/types/user';
import { Company } from 'lib/contexts/hr/company/domain/Company';
import { useRouter } from 'next/navigation';
import open from 'open';

type OrganizationalChartProps = {
  employees: EmployeeInCompany[];
  count: number;
  currentUser: UserProfileDataResponse;
  company: Company;
};

let OrgChart = null;
import('d3-org-chart').then((mod) => (OrgChart = mod.OrgChart));

export const OrganizationalChart: FC<OrganizationalChartProps> = ({
  employees,
  count,
  currentUser,
  company,
}) => {
  const router = useRouter();
  const { session } = useSession();
  const [chart] = useState(new OrgChart());
  const [showModal, setShowModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const employeesData = getOrgChartEmployeesData(employees, company);
  const employeesWithAlert = employeesData.filter((employee) => employee.alert);
  const [openAlertDetails, setOpenAlertDetails] = useState(false);

  const getNodeToCenter = useCallback(() => {
    return employees.find((employee) => employee.line_manager_id === null);
  }, []);

  const collapseAll = () => {
    if (chart) {
      const nodeToCenter = getNodeToCenter();
      if (nodeToCenter) {
        chart.setCentered(nodeToCenter.id).collapseAll();
      } else {
        chart.collapseAll();
      }
    }
  };

  const canShowProfile = useMemo(() => {
    if (selectedEmployee && currentUser) {
      if (
        isLineManager(currentUser.id, selectedEmployee) ||
        session.account_type !== AccountType.Employee
      ) {
        return true;
      }
      return false;
    }
  }, [selectedEmployee, currentUser]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const showDetails = (employeeId: string) => {
    const employeeDetails = findEmployee(employeeId);
    // @ts-ignore
    if (employeeDetails) {
      setSelectedEmployee(employeeDetails);
      setShowModal(true);
    }
  };

  const findEmployee = (employeeId: string) => {
    return employees.find((employee) => employee.id === employeeId);
  };

  const findCurrentUser = () => {
    if (chart) {
      chart.setCentered(currentUser.id).render();
    }
  };

  const goToProfile = (userId: string) => {
    router.push(`/profile?employee_id=${userId}`);
  };

  return (
    <>
      {session?.account_type !== AccountType.Employee &&
        employeesWithAlert.length > 0 && (
          <div className="w-full bg-white rounded-lg shadow-md mb-10 p-6">
            <div className="flex md:flex-row flex-col justify-between gab-2">
              <div className="font-bold text-medium md:mb-0 mb-2">
                <div
                  onClick={() => setOpenAlertDetails(!openAlertDetails)}
                  className="flex"
                >
                  <ChevronDownIcon className="w-4 h-4 text-brand-gray-900 cursor-pointer mr-1 mt-1" />
                  <div>
                    Quick Action Needed: Ensure Correct Line Manager Matches
                  </div>
                </div>
              </div>
              <div className="flex md:mb-0 mb-2 md:max-w-xs flex-wrap">
                <>
                  {employeesWithAlert.map((employee, i) => {
                    if (
                      i < 5 ||
                      employeesWithAlert.length === 6 ||
                      openAlertDetails
                    ) {
                      return (
                        <div key={employee.id} className="ml-2 mb-2">
                          <Tooltip
                            rounded={false}
                            tooltip={`${employee.first_name} ${employee.last_name}`}
                          >
                            <div
                              onClick={() => goToProfile(employee.id)}
                              className="cursor-pointer"
                            >
                              <Avatar
                                first_name={employee.first_name}
                                last_name={employee.last_name}
                                className="font-semibold"
                              />
                            </div>
                          </Tooltip>
                        </div>
                      );
                    }
                  })}
                  {!openAlertDetails && employeesWithAlert.length > 6 && (
                    <div className="ml-2">
                      <Tooltip
                        rounded={false}
                        tooltip={employeesWithAlert
                          .map((e, i) =>
                            i >= 5 ? `${e.first_name} ${e.last_name} \n` : '',
                          )
                          .join('')}
                      >
                        <Avatar
                          first_name="5"
                          last_name="+"
                          bgColor="bg-black"
                          textColor="text-white"
                          className="font-semibold"
                        />
                      </Tooltip>
                    </div>
                  )}
                </>
              </div>
            </div>
            {openAlertDetails && (
              <div className="text-brand-gray-800">
                Spot team members in this section? <br />
                <br />
                They might need a little help with their manager setup because:
                <br />
                a) They're in a mix-up where two employees are assigned to each
                other. <br />
                b) Their assigned manager is no longer part of the organisation.
                <br />
                c) They've accidentally become their own line manager. <br />
                Remember, CEO/Founder doesn't need a Line Manager.
                <br />
                <br /> A simple update to their Line Manager in their profiles
                will sort everything out.
              </div>
            )}
          </div>
        )}
      <section className="w-full bg-white rounded-lg pb-10 shadow-sm">
        <section className="flex justify-between p-7 items-center">
          <h2 className="font-bold text-xl">{count} employees</h2>
          <div className="flex items-center gap-10">
            <button
              onClick={collapseAll}
              className="btn-icon bg-brand-gray-900 text-white spacing-md rounded-full"
            >
              <span className="mr-2">Collapse all</span>{' '}
              <XIcon className="h-3 w-3" />
            </button>
            {currentUser && (
              <div onClick={findCurrentUser} className="cursor-pointer">
                <Tooltip tooltip="Find Me">
                  <Avatar
                    first_name={currentUser?.first_name}
                    last_name={currentUser?.last_name}
                    size="medium"
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </section>

        <PersonalDetailsModal
          canShowProfile={canShowProfile}
          selectedEmployee={selectedEmployee}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
        />

        {employees && currentUser && chart && (
          <EmployeesChart
            company={company}
            chart={chart}
            employees={employees}
            currentUser={currentUser}
            showDetails={showDetails}
          />
        )}
      </section>
    </>
  );
};
