import useSWR from 'swr';
import { AllEmployeeInCompany } from 'lib/types/company';
import { fetcher } from 'lib/utils';

export function useCompanyOrgChart() {
  const { data, error, mutate } = useSWR<AllEmployeeInCompany>(
    `/api/v1/company/organizational-chart`,
    fetcher,
  );

  return {
    data,
    loading: !error && !data,
    error,
    refetch: () => mutate(),
  };
}
