export const chartStyle = `
<style>
  .employee_card {
    height:102px;
    width:236px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    border-width: 1px;
    border-color: #9CA3AF;
  }

  .employee_image {
    display: flex;
    flex-direction: column;
    position: fixed;
    transform: translateY(-1rem);
  }

  .employee_avatar {
    display:flex;
    align-items:center;
    justify-content: center;
    border-radius: 9999px;
    color: white;
    width: 3rem;
    height:3rem;
  }

  .nameLink:hover {
    color: #4FCE70;
  }
  .tooltip {
    display:inline-block;
    position:relative;
    text-align:left;
  }

  .tooltip .top {
    min-width: 130px;
    top:-5px;
    left:50%;
    transform:translate(-50%, -100%);
    padding:4px 8px;
    color:#FFF;
    background-color:#1F1F1F;
    font-weight: 400;
    font-family: Red Hat Display, sans-serif;
    font-size:12px;
    line-height: 16px;
    border-radius:40px;
    text-align: center;
    position:absolute;
    z-index:99999999;
    box-sizing:border-box;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
    visibility:hidden; opacity:0; transition:opacity 0.8s;
  }

  .tooltip:hover .top {
    visibility:visible; opacity:1;
  }

  .tooltip .top i {
    position:absolute;
    top:100%;
    left:50%;
    margin-left:-12px;
    width:24px;
    height:12px;
    overflow:hidden;
  }

  .tooltip .top i::after {
    content:'';
    position:absolute;
    width:12px;
    height:12px;
    left:50%;
    transform:translate(-50%,-50%) rotate(45deg);
    background-color:#1F1F1F;
    box-shadow:0 1px 8px rgba(0,0,0,0.5);
  }
</style>
`;
