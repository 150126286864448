import clx from 'classnames';
import { seededRandom } from 'lib/utils';
import { userFullName } from 'lib/utils/user';
import Tooltip from '../Tooltip';

type AvatarProps = {
  first_name: string;
  last_name: string;
  size?: 'xs' | 'md' | 'small' | 'medium' | 'large';
  className?: string;
  tooltip?: string;
  bgColor?: string;
  textColor?: string;
};

const bgColors = [
  'bg-blue-500',
  'bg-brand-primary-500',
  'bg-red-500',
  'bg-green-500',
  'bg-purple-500',
  'bg-yellow-500',
  'bg-pink-500',
  'bg-indigo-500',
];

function randomBgColor(name: string): string {
  const index = seededRandom(name, 0, bgColors.length - 1);
  return bgColors[index];
}

export function Avatar({
  first_name,
  last_name,
  className,
  size = 'small',
  tooltip,
  bgColor,
  textColor,
}: AvatarProps) {
  const firstLetter = first_name?.toString().slice(0, 1).toUpperCase();
  const secondLetter = last_name?.toString().slice(0, 1).toUpperCase();
  return (
    <div
      className={clx(
        'flex justify-center items-center rounded-full text-white',
        bgColor
          ? bgColor
          : randomBgColor(userFullName({ first_name, last_name })),
        textColor,
        {
          'h-[20px] w-[20px] min-w-[20px] min-h-[20px] text-xs': size === 'xs',
          'h-[40px] w-[40px] min-w-[40px] min-h-[40px] text-md': size === 'md',
          'h-6 w-6 min-w-[32px] min-h-[32px] text-md': size === 'small',
          'h-12 w-12 min-w-[3rem] min-h-[3rem] text-lg': size === 'medium',
          'h-24 w-24 min-w-[6rem] min-h-[6rem] text-4xl': size === 'large',
        },
        className,
      )}
    >
      {tooltip && (
        <Tooltip tooltip={tooltip}>
          {firstLetter}
          {secondLetter}
        </Tooltip>
      )}
      {!tooltip && (
        <>
          {firstLetter}
          {secondLetter}
        </>
      )}
    </div>
  );
}
