import { VFC } from 'react';
import clx from 'classnames';

type PersonalDetailProps = {
  label: string;
  value: string | number;
  as?: 'tag' | 'text';
  tagColorScheme?: 'red' | 'gray' | 'green' | 'blue' | 'purple' | 'yellow';
  capitalize?: boolean;
};

function isTag(asVariant: PersonalDetailProps['as']): boolean {
  return asVariant === 'tag';
}

export const PersonalDetail: VFC<PersonalDetailProps> = ({
  label,
  value = '-',
  as: asVariant,
  tagColorScheme,
  capitalize = true,
}) => (
  <div className="min-h-[24px]">
    <div className="font-bold"> {label}: </div>
    <div
      className={clx({
        capitalize,
        'text-base text-gray-500': !isTag(asVariant),
        'rounded-md inline-block px-2 text-md': isTag(asVariant),
        'bg-red-200 text-red-500': isTag(asVariant) && tagColorScheme === 'red',
        'bg-gray-200 text-brand-gray-900':
          isTag(asVariant) && tagColorScheme === 'gray',
        'bg-green-200 text-green-500':
          isTag(asVariant) && tagColorScheme === 'green',
        'bg-blue-200 text-blue-500':
          isTag(asVariant) && tagColorScheme === 'blue',
        'bg-purple-200 text-purple-500':
          isTag(asVariant) && tagColorScheme === 'purple',
        'bg-yellow-200 text-brand-gray-900':
          isTag(asVariant) && tagColorScheme === 'yellow',
      })}
    >
      {' '}
      {value || '-'}{' '}
    </div>
  </div>
);
