import { ArrowCircleLeftIcon, FlagIcon } from '@heroicons/react/solid';
import { Button } from '../../../../atoms/Button';
import { Company } from '../../../../../lib/contexts/hr/company/domain/Company';
import { Input } from '../../../../atoms/Input';
import { useEffect, useState } from 'react';
import { useCountries } from '../../../../../lib/hooks/countries/useCountries';
import { Autocomplete } from '../../../../atoms/Autocomplete';
import { countryCodeToEmoji } from '../../../../../lib/utils';
import { getChangesMD5 } from '../../../../../lib/utils/md5';

export default function EditHQDetails({
  company,
  payrollConfig,
  onGoBack,
  refetch,
}: {
  company?: Company;
  payrollConfig?: {
    governmentGatewayUserId?: string;
    governmentGatewayPassword?: string;
    accountOfficeReference?: string;
  };
  onGoBack?: () => void;
  refetch?: () => void;
}) {
  const [name, setName] = useState<string>(company?.name ?? '');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [address, setAddress] = useState<string>(company?.address ?? '');
  const [vat, setVat] = useState<string>(company?.vat ?? '');
  const [countryId, setCountryId] = useState<string | undefined>(
    company?.country?.id,
  );
  const [city, setCity] = useState<string | undefined>(company?.city);
  const { data: countries, loading: loadingCountries } = useCountries();
  const countryOptions = countries?.map((c) => ({
    key: c.id,
    value: `${countryCodeToEmoji(c.alpha_2_code)}   ${c.name}`,
  }));
  const [governmentGatewayUserId, setGovernmentGatewayUserId] = useState<
    string | undefined
  >(payrollConfig?.governmentGatewayUserId);
  const [accountOfficeReference, setAccountOfficeReference] = useState<
    string | undefined
  >(payrollConfig?.accountOfficeReference);
  const [governmentGatewayPassword, setGovernmentGatewayPassword] = useState<
    string | undefined
  >(payrollConfig?.governmentGatewayPassword);
  const initialMD5 = getChangesMD5({
    name: company?.name,
    address: company?.address,
    vat: company?.vat,
    countryId: company?.country?.id,
    city: company?.city,
    governmentGatewayPassword: payrollConfig?.governmentGatewayPassword,
    governmentGatewayUserId: payrollConfig?.governmentGatewayUserId,
    accountOfficeReference: payrollConfig?.accountOfficeReference,
  });
  const [changesMD5, setChangesMD5] = useState('');

  useEffect(() => {
    setChangesMD5(
      getChangesMD5({
        name,
        address,
        vat,
        countryId,
        city,
        governmentGatewayPassword,
        governmentGatewayUserId,
        accountOfficeReference,
      }),
    );
  }, [
    name,
    address,
    vat,
    countryId,
    city,
    governmentGatewayPassword,
    governmentGatewayUserId,
    accountOfficeReference,
  ]);

  const onSave = async () => {
    setIsSaving(true);
    await fetch('/api/v1/company/update', {
      method: 'POST',
      body: JSON.stringify({
        name,
        address,
        vat,
        countryId,
        city,
      }),
    });
    await fetch('/api/v2/payroll/admin-company-config', {
      method: 'POST',
      body: JSON.stringify({
        governmentGatewayUserId,
        governmentGatewayPassword,
        accountOfficeReference,
      }),
    });
    setIsSaving(false);
    if (refetch) {
      refetch();
    }
  };

  return (
    <div className="w-full bg-white rounded-md min-h-screen p-4">
      <div className="flex w-full justify-between items-center">
        <div className="flex items-center">
          {onGoBack && (
            <span
              className="cursor-pointer"
              onClick={() => (onGoBack ? onGoBack() : {})}
            >
              <ArrowCircleLeftIcon className="w-8 mr-3" />
            </span>
          )}
          <h2 className="font-bold text-xl h-[40px] flex items-center">
            HQ Details
          </h2>
        </div>
        <Button
          disabled={changesMD5 === initialMD5 || isSaving}
          className=""
          onClick={onSave}
        >
          Save HQ Details
        </Button>
      </div>
      <div className="md:grid-cols-2 grid-cols-1 grid py-10">
        <div className="md:order-last w-full p-2">
          <div className="max-w-[350px] mx-auto p-6">
            <div className="font-bold text-xl mb-2">{company?.name}</div>
            <div className="mb-1">
              {company?.city}{' '}
              {company?.country?.code
                ? countryCodeToEmoji(company.country.code)
                : ''}
            </div>
            <div className="mb-1">{company?.address}</div>
            <div className="mb-1">{company?.vat}</div>

            {payrollConfig?.governmentGatewayUserId && (
              <div className="mb-2">
                {payrollConfig?.governmentGatewayUserId}
              </div>
            )}
            {payrollConfig?.governmentGatewayPassword && (
              <div className="mb-1">
                {payrollConfig?.governmentGatewayPassword}
              </div>
            )}
          </div>
        </div>
        <div className="w-full p-2">
          <Input
            className={'mb-6'}
            value={name}
            label="Company name"
            placeholder={'Company name'}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="grid grid-cols-2 gap-2 w-full mb-6">
            <Input
              className="w-full"
              value={city}
              label="City"
              placeholder={'City'}
              onChange={(e) => setCity(e.target.value)}
            />
            {loadingCountries ? (
              <Input
                disabled={true}
                className={'blur w-full'}
                placeholder="Country"
              />
            ) : (
              <Autocomplete
                className="w-full"
                onSelect={(d) => setCountryId(d.key as any)}
                options={countryOptions}
                label="Country"
                placeholder="Country"
                showLabel={true}
                // @ts-ignore
                value={
                  // tslint:disable:ter-indent
                  countryId
                    ? {
                        key: countryId,
                        value: countryOptions.find((o) => o.key === countryId)
                          ?.value as unknown as string,
                      }
                    : null
                  // tslint:enable:ter-indent
                }
              />
            )}
          </div>
          <Input
            className={'mb-6'}
            value={address}
            label="Company address"
            placeholder={'Company address'}
            onChange={(e) => setAddress(e.target.value)}
          />
          <Input
            className={'mb-6'}
            value={vat}
            label="VAT"
            placeholder={'VAT'}
            onChange={(e) => setVat(e.target.value)}
          />
          <Input
            className={'mb-7'}
            value={accountOfficeReference}
            label="Account Office Reference"
            placeholder={'Account Office Reference'}
            onChange={(e) => setAccountOfficeReference(e.target.value)}
          />
          <Input
            className={'mb-7'}
            value={governmentGatewayUserId}
            label="Goverment Gateway User ID"
            placeholder={'Goverment Gateway User ID'}
            onChange={(e) => setGovernmentGatewayUserId(e.target.value)}
          />
          <Input
            className={'mb-7'}
            value={governmentGatewayPassword}
            label="Goverment Gateway Password"
            placeholder={'Goverment Gateway Password'}
            onChange={(e) => setGovernmentGatewayPassword(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
