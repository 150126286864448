'use client';
import { ReactNode } from 'react';
import clx from 'classnames';
import { useRouter } from 'next/navigation';

type ButtonProps = {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  rounded?: boolean;
  type?: 'default' | 'primary' | 'flat';
  routerPath?: string;
};
export function Button(props: ButtonProps) {
  const rounded = props.rounded ?? true;
  const type = props.type ?? 'default';
  const router = useRouter();

  const onClick = () => {
    if (props.routerPath) {
      router.push(props.routerPath);
    }
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <button
      className={clx(
        'btn-icon cursor-pointer disabled:opacity-25 disabled:cursor-not-allowed',
        props.className,
        {
          'py-2': !props.className?.includes('py-'),
          'px-5': !props.className?.includes('px-'),
          'bg-black text-white': type === 'default',
          'bg-brand-primary-100 text-brand-primary-900': type === 'primary',
          'rounded-full': rounded,
          'bg-transparent text-brand-primary-900': type === 'flat',
          'font-medium': !props.className?.includes('font-bold'),
        },
      )}
      disabled={props.disabled}
      onClick={onClick}
    >
      {props.children}
    </button>
  );
}
