import useSWR from 'swr';
import { CountriesData } from 'lib/prisma/queries/countries/get-countries';
import { fetcher } from 'lib/utils';

export function useCountries() {
  const URL = `/api/v1/countries/get-countries`;

  const { data, error, mutate } = useSWR(URL, fetcher);
  return {
    data: data as CountriesData,
    loading: !error && !data,
    error,
    refetch: () => mutate(),
  };
}
