import { IconProps } from 'lib/types';
import clx from 'classnames';
export default function CreditCardIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clx('fill-current', props.className)}
    >
      <path d="M16.3636 0C18.3719 0 20 1.67893 20 3.75V11.25C20 13.3211 18.3719 15 16.3636 15H3.63636C1.62806 15 0 13.3211 0 11.25V3.75C0 1.67893 1.62806 0 3.63636 0H16.3636ZM18.1818 5.48344H1.81818V11.25C1.81818 12.2855 2.63221 13.125 3.63636 13.125H16.3636C17.3678 13.125 18.1818 12.2855 18.1818 11.25V5.48344ZM7.4026 9.84375C7.90468 9.84375 8.31169 10.2635 8.31169 10.7812C8.31169 11.299 7.90468 11.7188 7.4026 11.7188H4.15584C3.65377 11.7188 3.24675 11.299 3.24675 10.7812C3.24675 10.2635 3.65377 9.84375 4.15584 9.84375H7.4026ZM16.3636 1.875H3.63636C2.67817 1.875 1.89309 2.63939 1.82324 3.60912H18.1768C18.1069 2.63939 17.3218 1.875 16.3636 1.875Z" />
    </svg>
  );
}
