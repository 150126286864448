import { IconProps } from '../types';

export default function FolderIcon(props: IconProps) {
  const sizeHeight = props.sizeH ?? 22;
  const sizeWidth = props.sizeW ?? 20;
  return (
    <svg
      width={sizeWidth}
      height={sizeHeight}
      viewBox={`0 0 ${sizeHeight} ${sizeWidth}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C2.20435 0 1.44129 0.31607 0.87868 0.87868C0.31607 1.44129 0 2.20435 0 3V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H19C19.7957 20 20.5587 19.6839 21.1213 19.1213C21.6839 18.5587 22 17.7957 22 17V6C22 5.20435 21.6839 4.44129 21.1213 3.87868C20.5587 3.31607 19.7957 3 19 3H10.5352L8.83205 0.4453C8.64658 0.167101 8.33435 0 8 0H3ZM2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H7.46482L9.16795 4.5547C9.35342 4.8329 9.66565 5 10 5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V3C2 2.73478 2.10536 2.48043 2.29289 2.29289Z"
        fill={props.color ?? '#1F973F'}
      />
    </svg>
  );
}
