import useSWR from 'swr';
import { fetcher } from 'lib/utils';
import { Location } from '../../contexts/hr/company/domain/Location/Location';

export function useCompanyLocations() {
  const { data, error, mutate } = useSWR<any[]>(
    `/api/v1/company/locations`,
    fetcher,
  );

  return {
    data: data ? data.map(Location.fromJson) : undefined,
    loading: !error && !data,
    error,
    refetch: () => mutate(),
  };
}
