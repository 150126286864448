import useSWR from 'swr';
import { fetcher } from '../../utils';
import { Company } from '../../contexts/hr/company/domain/Company';

export function useCompany() {
  const { data, error, mutate } = useSWR<Company>(`/api/v1/company`, fetcher);

  return {
    data: data ? Company.fromJson(data) : data,
    loading: !error && !data,
    error,
    refetch: () => mutate(),
  };
}
