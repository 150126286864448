import * as React from 'react';
import useSWR from 'swr';
import { UserProfileDataResponse } from 'lib/prisma/queries/user/get-user-profile-data';
import { fetcher } from 'lib/utils';

export type UseProfileProps = {
  employee_id?: string;
};

export function useProfile({ employee_id }: UseProfileProps) {
  const queryString = employee_id ? `employee_id=${employee_id}` : '';
  const URL = `/api/v1/user/get-profile-data?${queryString}`;

  const { data, error, mutate } = useSWR(URL, fetcher);
  return React.useMemo(
    () => ({
      user: data as UserProfileDataResponse,
      loading: !error && !data,
      error,
      refetch: () => mutate(),
    }),
    [data, error],
  );
}
