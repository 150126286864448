import { ReactNode } from 'react';
import { Button } from 'components/atoms/Button';
import clx from 'classnames';
export default function MenuGridItem({
  title,
  icon,
  iconColor,
  iconBgColor,
  onClick,
  buttonText = 'View details',
  children,
  className,
}: {
  title?: string;
  children: ReactNode;
  icon?: ReactNode;
  iconColor?: string;
  iconBgColor?: string;
  onClick?: () => void;
  buttonText?: string;
  className?: string;
}) {
  return (
    <div
      className={clx(
        'w-full bg-white rounded-md px-6 py-4 flex justify-between flex-col truncate',
        className,
      )}
    >
      {title && (
        <div className="w-full border-b border-brand-gray-300 py-4">
          <div className="w-full flex justify-start items center">
            <span
              className={clx(
                'rounded-full w-[40px] h-[40px] flex justify-center items-center',
                {
                  'bg-gray-200': !iconBgColor,
                  [iconBgColor]: iconBgColor,
                  [iconColor]: iconColor,
                },
              )}
            >
              {icon}
            </span>
            <h3
              className={clx('text-xl font-bold h-[40px] flex items-center', {
                'ml-4': icon,
              })}
            >
              {title}
            </h3>
          </div>
        </div>
      )}
      <div className="w-full py-10 font-bold grow">{children}</div>
      {onClick && (
        <div className="w-full">
          <Button type="primary" onClick={() => onClick()} className="w-full">
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
}
