'use client';
import { SearchIcon } from '@heroicons/react/outline';
import React, {
  ChangeEvent,
  FC,
  useState,
  useCallback,
  useEffect,
} from 'react';
import debounce from 'lodash/debounce';

interface SearchProps {
  onSearch: (input: string) => void;
  placeholder: string;
  initialQuery: string;
}

const Search: FC<SearchProps> = ({ onSearch, placeholder, initialQuery }) => {
  const [query, setQuery] = useState<string>(initialQuery);

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      onSearch(value);
    }, 300),
    [onSearch],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setQuery(newValue);
      debouncedSearch(newValue);
    },
    [debouncedSearch],
  );

  useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  return (
    <div className="flex">
      <div className="flex justify-center items-center">
        <SearchIcon className="w-5 h-5 text-gray-400 mr-2" />
      </div>
      <input
        className="w-80 py-2"
        placeholder={placeholder}
        value={query}
        onChange={onChange}
      />
    </div>
  );
};

export default Search;
