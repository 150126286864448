import { Input } from '../../../../atoms/Input';
import { Autocomplete } from '../../../../atoms/Autocomplete';
import OptionButton from '../../../../organisms/OptionButton';
import { LocationTypeEnum } from '../../../../../lib/contexts/hr/company/domain/Location/LocationType';
import CompanyIcon from '../../../../../lib/icons/company';
import { Button } from '../../../../atoms/Button';
import { useEffect, useState } from 'react';
import { useCountries } from '../../../../../lib/hooks/countries/useCountries';
import { countryCodeToEmoji } from '../../../../../lib/utils';
import { Location } from '../../../../../lib/contexts/hr/company/domain/Location/Location';
import { getChangesMD5 } from '../../../../../lib/utils/md5';
import GlobeIcon from '../../../../../lib/icons/globe';
import CommandIcon from '../../../../../lib/icons/command';

export function EditLocationForm({
  className,
  loading,
  location,
  onSave,
  saveButtonText,
}: {
  className?: string;
  loading?: boolean;
  location?: Location;
  onSave?: () => void;
  saveButtonText?: string;
}) {
  const [inputCity, setInputCity] = useState<string>(location?.city ?? '');
  const [_loading, set_Loading] = useState<boolean>(loading);
  const [countryId, setCountryId] = useState<string | undefined>(
    location?.country?.id,
  );
  const [selectedLocationType, setSelectedLocationType] = useState<
    LocationTypeEnum | undefined
  >(location?.type);
  const { data: countries, loading: loadingCountries } = useCountries();
  const countryOptions = countries?.map((c) => ({
    key: c.id,
    value: `${countryCodeToEmoji(c.alpha_2_code)}   ${c.name}`,
  }));

  const initialMD5 = getChangesMD5({
    city: location?.city.trim() ?? '',
    countryId: location?.country?.id,
    locationType: location?.type,
  });
  const [changesMD5, setChangesMD5] = useState('');

  useEffect(() => {
    setChangesMD5(
      getChangesMD5({
        city: inputCity?.trim(),
        countryId,
        locationType: selectedLocationType,
      }),
    );
  }, [inputCity, countryId, selectedLocationType]);

  useEffect(() => {
    set_Loading(loading || _loading);
  }, [loading]);

  const onSaveLocation = async () => {
    set_Loading(true);
    await fetch('/api/v1/company/locations/save', {
      method: 'POST',
      body: JSON.stringify({
        id: location?.id,
        city: inputCity.trim(),
        countryId,
        type: selectedLocationType,
      }),
    });
    if (onSave) {
      onSave();
    }
    set_Loading(false);
  };
  return (
    <div className={className}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Input
          onChange={(e) => setInputCity(e.target.value)}
          value={inputCity}
          placeholder="City"
        />
        {loadingCountries ? (
          <Input
            disabled={true}
            className={'blur w-full'}
            placeholder="Country"
          />
        ) : (
          <Autocomplete
            className="w-full"
            onSelect={(d) => setCountryId(d.key as any)}
            options={countryOptions}
            label="Country"
            placeholder="Country"
            showLabel={true}
            // @ts-ignore
            value={
              // tslint:disable:ter-indent
              countryId
                ? {
                    key: countryId,
                    value: countryOptions.find((o) => o.key === countryId)
                      ?.value as unknown as string,
                  }
                : null
              // tslint:enable:ter-indent
            }
          />
        )}
      </div>
      <div className="mt-4">
        <div>Office type of location</div>
        <div className="grid grid-cols-3 gap-4 mt-2">
          <OptionButton
            selected={selectedLocationType === LocationTypeEnum.OFFICE}
            onClick={() => {
              if (selectedLocationType === LocationTypeEnum.OFFICE) {
                setSelectedLocationType(undefined);
                return;
              }
              setSelectedLocationType(LocationTypeEnum.OFFICE);
            }}
            icon={<CompanyIcon sizeH={24} sizeW={24} viewBox={'0 0 24 24'} />}
            label="Office based"
          />
          <OptionButton
            selected={selectedLocationType === LocationTypeEnum.REMOTE}
            onClick={() => {
              if (selectedLocationType === LocationTypeEnum.REMOTE) {
                setSelectedLocationType(undefined);
                return;
              }
              setSelectedLocationType(LocationTypeEnum.REMOTE);
            }}
            icon={<GlobeIcon />}
            label="Remote"
          />
          <OptionButton
            selected={selectedLocationType === LocationTypeEnum.HYBRID}
            onClick={() => {
              if (selectedLocationType === LocationTypeEnum.HYBRID) {
                setSelectedLocationType(undefined);
                return;
              }
              setSelectedLocationType(LocationTypeEnum.HYBRID);
            }}
            icon={<CommandIcon />}
            label="Hybrid"
          />
        </div>
      </div>
      <Button
        className={'mt-4'}
        onClick={() => onSaveLocation()}
        disabled={
          changesMD5 === initialMD5 ||
          !countryId ||
          inputCity.trim() === '' ||
          !selectedLocationType
        }
      >
        {saveButtonText ?? 'Add location'}
      </Button>
    </div>
  );
}
