import useSWR from 'swr';
import { fetcher } from 'lib/utils';
import { Department } from 'lib/contexts/hr/company/Department';

export function useCompanyDepartments() {
  const { data, error, mutate } = useSWR<Department[]>(
    `/api/v1/company/departments`,
    fetcher,
  );

  return {
    data: data ? data.map(Department.fromJson) : undefined,
    loading: !error && !data,
    error,
    refetch: () => mutate(),
  };
}
