'use client';
import Dialog from '../../../../organisms/Dialog';
import { EditLocationForm } from './EditLocationForm';
import { Location } from '../../../../../lib/contexts/hr/company/domain/Location/Location';

export function EditLocationModal({
  location,
  open,
  onClose,
  refetch,
}: {
  open: boolean;
  location: Location;
  onClose: () => void;
  refetch: () => void;
}) {
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <div className="font-bold text-2xl mb-8">Edit location</div>
        <div className="mt-2">
          <EditLocationForm
            saveButtonText="Save"
            location={location}
            onSave={() => {
              refetch();
              onClose();
            }}
          />
        </div>
      </Dialog>
    </>
  );
}
